import { useGetSettingsDetails } from "@/hooks/settings.hook";
import { getValue } from "@/utils/object";
import SocialMedia from "../socialMedia/SocialMedia";
import { useGetAllTourType } from "@/hooks/activities.hook";
import { useNavigate } from "react-router-dom";
import { PATH } from "@/constants/path";

function Footer() {
  const { data: companyDetails } = useGetSettingsDetails();
  const navigate = useNavigate();
  const { data: tourType } = useGetAllTourType();
  const handleNavigate = (name) => {
    navigate(`${PATH.tourType}/${name}`);
  };

  return (
    <>
      <div className=" w-full bg-[rgb(12,54,43)] text-white px-5 md:px-10 lg:px-18">
        <div className="flex-row">
          <div className="flex flex-col md:flex-row justify-start">
            <div className="company-description md:border-r  my-5 w-full flex-1 justify-start">
              <div className="flex flex-row justify-start">
                <a href={PATH.HOME}>
                  <img
                    src={getValue(companyDetails, "[0].website_logo")}
                    alt="footer-logo"
                    className="w-32"
                  />
                </a>
              </div>
              <p className="text-sm pt-5">
                Discover the world&apos;s most captivating destinations with
                Glory World Holidays. We offer personalized travel experiences,
                ensuring your journey is as unique as you are. Explore, dream,
                and embark on unforgettable adventures with us.
              </p>
              <div className="pt-5">
                <SocialMedia
                  companyProfile={
                    companyDetails && getValue(companyDetails, "[0]")
                  }
                />
              </div>
            </div>
            <div className="flex flex-col md:basis-2/3">
              <div className="flex flex-col md:flex-row md:w-full">
                <div className="learn-more md:p-3 my-5 w-full">
                  <p className="border-b text-xl font-bold md:pl-2 mb:4">
                    Quick Links
                  </p>
                  <ul className="md:pl-3 text-xs pt-3 md:text-sm md:font-semibold flex flex-col  md:flex-col ">
                    <li className="p-1 whitespace-nowrap">
                      <a href={PATH.ABOUT}>About Us</a>
                    </li>
                    <li className="p-1 whitespace-nowrap">
                      <a href={PATH.CONTACT}>Contact us</a>
                    </li>
                    <li className="p-1 whitespace-nowrap">
                      <a
                        target="_blank"
                        href="https://blog.gloryworldholidays.com/"
                      >
                        Blog
                      </a>
                    </li>
                    <li className="p-1 whitespace-nowrap">
                      <a href={PATH.Terms}>Terms & Conditions</a>
                    </li>
                  </ul>
                </div>

                <div className="activities md:p-3 my-5 w-full">
                  <p className="border-b text-xl font-bold md:pl-2 mb:4">
                    Tour Types
                  </p>
                  <ul className="md:pl-3 text-xs pt-3 md:text-sm md:font-semibold flex flex-col ">
                    {tourType &&
                      tourType?.slice(0, 5).map((data) => (
                        <li
                          className="p-1 whitespace-nowrap"
                          key={getValue(data, "id")}
                        >
                          <button
                            onClick={() =>
                              handleNavigate(getValue(data, "name"))
                            }
                          >
                            {data.name}
                          </button>
                        </li>
                      ))}
                  </ul>
                </div>

                <div className="Contact-us md:p-3 my-5 w-full">
                  <p className="border-b text-xl font-bold md:pl-2 mb:4">
                    Contact
                  </p>
                  <ul className="md:pl-3 text-xs pt-3 md:text-sm md:font-semibold flex flex-col sm:flex-row md:flex-col ">
                    <li className="p-1 whitespace-nowrap">
                      <i className="fas fa-envelope"></i>{" "}
                      <a
                        href={`mailto:${getValue(
                          companyDetails,
                          "[0].email[0].email"
                        )}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {getValue(companyDetails, "[0].email[0].email")}
                      </a>
                    </li>
                    <li className="p-1 whitespace-nowrap">
                      <i className="fas fa-phone"></i>{" "}
                      <a
                        href={`tel:${getValue(
                          companyDetails,
                          "[0].phone_number"
                        )}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {getValue(companyDetails, "[0].phone_number")}
                      </a>
                    </li>

                    <li className="p-1 whitespace-nowrap">
                      <i className="fas fa-map-marker-alt"></i>{" "}
                      {getValue(companyDetails, "[0].address[0].city") +
                        " " +
                        getValue(companyDetails, "[0].address[0].country")}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <p className="text-center bg-black text-xs w-full py-4 text-white">
        &copy; Copyright Glory World Holidays Pvt Ltd 2024. All rights reserved.
      </p>
    </>
  );
}

export default Footer;

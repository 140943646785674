import About from "../../assets/images/getimage.jpg";
const Aboutus = () => {
  return (
    <>
      <div className="flex flex-col lg:flex-row justify-between gap-8 p-6 md:p-12 mt-6">
        <div className="w-full lg:w-1/2">
          <img
            src={About}
            alt="About Company"
            className="w-full h-auto  object-cover rounded-md"
          />
        </div>
        <div className="flex  flex-col  justify-center ">
        <h1 className="font-bold text-2xl md:text-5xl  lg:max-w-md">About Us</h1>
          <p className=" text-[#4F5E70] pt-5 max-w-md md:max-w-xl text-justify">
            At Glory Travel, we are on a mission to make travel an enriching and
            accessible experience for everyone – whether that means exploring
            your own backyard or venturing to the far corners of the globe. Our
            team of seasoned travel experts draws from decades of firsthand
            experience to meticulously curate the best domestic and
            international destinations, providing you with the insights and
            resources needed to turn your travel dreams into reality.
          </p>
          <p className=" text-[#4F5E70] pt-5 max-w-md md:max-w-xl text-justify">
            From the stunning natural landscapes of our home country to the
            vibrant cultures and awe-inspiring wonders found across continents,
            we are passionate about sharing the joys of exploration. Through our
            user-friendly booking platform, comprehensive destination guides,
            and personalized trip planning services, we empower travelers of all
            interests and experience levels to embark on unforgettable journeys
            near and far. Our commitment to sustainable and responsible tourism
            practices ensures that the places we love remain protected for
            generations to come.
          </p>
        </div>
      </div>
    </>
  );
};

export default Aboutus;

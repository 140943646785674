export const PATH = {
  HOME: "/",
  Login: "/login",
  ABOUT: "/about",
  CONTACT: "/contact",
  DESTINATIONDETAILS: "/destinations/",
  TOURDETAILS: "/tours/",
  Tour: "/tour",
  Terms: "/terms-conditions",
  tourType: "/list-tour-types",
  verifyEmail: "/auth/verifyEmail",
  user: "/user",
  book: "/book",
};

import { useState, useEffect } from "react";
import { Tab } from "@headlessui/react";
import PropTypes from "prop-types";

import { getValue } from "@/utils/object";
import {
  useGetAllDomesticPackage,
  useGetAllDomesticPackageCount,
} from "@/hooks/typePackage.hook";
import PlacesCarousel from "@/components/home/PlacesCarousel";

const SkeletonTab = () => (
  <div className="animate-pulse bg-gradient-to-r from-gray-200 via-gray-300 to-gray-200 rounded-full px-4 py-2 w-40 h-10"></div>
);

const SkeletonPanel = () => (
  <div className="w-full h-96 bg-gradient-to-r from-gray-200 via-gray-300 to-gray-200 animate-pulse"></div>
);

const DomesticDestination = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { data: allPackageCount } = useGetAllDomesticPackageCount();
  const { data: allPackage, isLoading } = useGetAllDomesticPackage(
    allPackageCount?.[selectedIndex]?.id || ""
  );
  const [showSkeleton, setShowSkeleton] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSkeleton(false);
    }, 1000); // Delay of 1000ms (1 second)

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="">
      <div className="flex flex-col">
        <Tab.Group as="div">
          <div className="w-full flex flex-col md:flex-row gap-5 justify-between px-5 md:px-16 ">
            <div className="text-xl md:text-3xl">
              <span className="font-bold">Domestic</span>{" "}
              <span className="font-bold">Tours</span>{" "}
            </div>
            <div className="max-w-full overflow-x-auto whitespace-nowrap scroll-mx-0 flex gap-5 md:gap-10 hide-scrollbar">
              {showSkeleton ? (
                <>
                  <SkeletonTab />
                  <SkeletonTab />
                  <SkeletonTab />
                </>
              ) : (
                allPackageCount?.map((item, index) => (
                  <Tab
                    key={getValue(item, "id")}
                    className={({ selected }) =>
                      `${
                        selected
                          ? "text-white bg-primary"
                          : "text-black hover:bg-primary hover:text-white"
                      } border-[1px] rounded-full px-4 py-2`
                    }
                    onClick={() => setSelectedIndex(index)}
                  >
                    {getValue(item, "name")}
                  </Tab>
                ))
              )}
            </div>
          </div>
          <Tab.Panels className="w-full">
            {showSkeleton ? (
              <SkeletonPanel />
            ) : (
              allPackageCount?.map((item, index) => (
                <Tab.Panel key={index} className="w-full">
                  {selectedIndex === index && (
                    <>
                      {!isLoading && allPackage ? (
                        <PlacesCarousel
                          data={getValue(allPackage, "packages", [])}
                          key={getValue(item, "id")}
                        />
                      ) : (
                        <SkeletonPanel />
                      )}
                    </>
                  )}
                </Tab.Panel>
              ))
            )}
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
};

DomesticDestination.propTypes = {
  item: PropTypes.shape({
    continent_id: PropTypes.string,
    continent: PropTypes.string,
  }),
  index: PropTypes.number,
};

export default DomesticDestination;

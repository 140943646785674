import ContactDetails from "../../components/contact/ContactDetails";
import { FormProvider, useForm } from "react-hook-form";
import CustomInput from "../form/custom/CustomInput";
import CustomTextArea from "../form/custom/CustomtextArea";
import { showErrorMessage } from "@/utils/toast";
import { getValue } from "@/utils/object";
import { useCreateContactMessage } from "@/hooks/contact.hook";

const ContactUsForm = () => {
  const methods = useForm();
  const { mutateAsync: createMessage, isPending } = useCreateContactMessage();

  const onSubmit = async (data) => {
    try {
      const resData = {
        name: data.name,
        email: data.email,
        message: data.message,
      };
      await createMessage(resData);
      methods.reset();
    } catch (err) {
      showErrorMessage(getValue(err, "message"));
    }
  };

  return (
    <div className="mt-12">
      <div className="max-w-4xl mx-auto p-10 md:p-8 lg:p-6 bg-gray-200 rounded-sm shadow-md">
        <div className="flex flex-col md:flex-row gap-5 md:gap-2 items-center justify-between ">
          <ContactDetails />
          <div>
            <h2 className="text-md font-semibold text-center text-secondary">
              Fill up the form and our team will get back to you within 24 hours
            </h2>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <div className="flex flex-col gap-5 py-5">
                  <div>
                    <div className="pb-1 text-secondary font-medium">Name</div>
                    <CustomInput
                      type="text"
                      name="name"
                      placeHolder="Enter your name"
                      required
                    />
                  </div>
                  <div>
                    <div className="pb-1 text-secondary font-medium">Email</div>

                    <CustomInput
                      type="text"
                      name="email"
                      placeHolder="Enter your email"
                      required
                    />
                  </div>

                  <div>
                    <div className="pb-1 text-secondary font-medium">
                      Message
                    </div>
                    <CustomTextArea
                      name="message"
                      placeHolder={"message"}
                      required
                    />
                  </div>
                </div>
                <div>
                  <button
                    type="submit"
                    className="w-full text-lg text-white font-medium px-3 py-2 border rounded-sm bg-primary hover:bg-[#007a5a] hover:border-green-800"
                    disabled={isPending}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsForm;

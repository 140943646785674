import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PATH } from "./constants/path";
import Layout from "./layout/Layout";
import PrivateRouteWrapper from "./layout/PrivateRouteWrapper";
import Login from "./pages/auth/login";
import { useGetSettingsDetails } from "./hooks/settings.hook";
import { useAuthContext } from "./hooks/contextConsumer.hook";
import { useEffect } from "react";
import { getValue } from "./utils/object";

function App() {
  const { data: companyDetails } = useGetSettingsDetails();

  console.log({ companyDetails });
  const { setCompanyProfile } = useAuthContext();

  useEffect(() => {
    setCompanyProfile(getValue(companyDetails, "[0]"));
    console.log("hello");
  }, [companyDetails, setCompanyProfile]);
  return (
    <BrowserRouter>
      <ToastContainer />
      <Routes>
        <Route path={PATH.Login} element={<Login />} />
        <Route element={<PrivateRouteWrapper isLoggedIn={true} />}>
          <Route path="/*" element={<Layout />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;

import { useCreateRating } from "@/hooks/rating.hook";
import { getValue } from "@/utils/object";
import { showErrorMessage, showSuccessMessage } from "@/utils/toast";
import PropTypes from "prop-types";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import CustomTextArea from "../form/custom/CustomtextArea";
import { FaStar } from "react-icons/fa";

const ReviewRating = ({ id }) => {
  const methods = useForm();
  const [rating, setRating] = useState(0);

  const { mutateAsync: createRating } = useCreateRating();

  const onSubmit = async (data) => {
    try {
      const resData = {
        rating: rating,
        review_text: data.message,
      };
      const response = await createRating({ id: id, body: resData });
      showSuccessMessage(getValue(response, "message"));
      methods.reset();
      setRating(0);
    } catch (err) {
      showErrorMessage(getValue(err, "message"));
    }
  };

  const handleStarClick = (index) => {
    setRating(index);
  };

  return (
    <div>
      <div className="py-10">
        <div className="font-bold text-lg uppercase">Give Your Rating</div>

        <div className="flex flex-col gap-2 pt-5">
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <CustomTextArea
                name="message"
                placeHolder="Enter your Comments"
                styles={'border'}
              />

              <div className="flex space-x-2 mt-4">
                {[1, 2, 3, 4, 5].map((star) => (
                  <FaStar
                    key={star}
                    size={24}
                    className={`cursor-pointer ${
                      rating >= star ? "text-primary" : "text-gray-300"
                    }`}
                    onClick={() => handleStarClick(star)}
                  />
                ))}
              </div>

              <button
                type="submit"
                className="mt-4 bg-primary text-white px-4 py-2 rounded-md"
              >
                Submit Review
              </button>
            </form>
          </FormProvider>
        </div>
      </div>
    </div>
  );
};

ReviewRating.propTypes = {
  id: PropTypes.string.isRequired,
};

export default ReviewRating;

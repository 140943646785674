import React from "react";
import PropTypes from "prop-types";
import { getValue } from "@/utils/object";
import { checkIfEmpty } from "@/utils/validation";
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaLinkedinIn,
  FaTiktok,
} from "react-icons/fa";

const SocialMedia = ({ companyProfile }) => {
  const filterSocialMedia = [];

  if (!checkIfEmpty(companyProfile)) {
    const socialMediaLinks = getValue(companyProfile, "social_media_links");
    socialMediaLinks.forEach((link) => {
      if (
        link.name === "facebook" ||
        link.name === "instagram" ||
        link.name === "tiktok" ||
        link.name === "twitter" ||
        link.name === "linkedin"
      ) {
        filterSocialMedia.push({
          name: link.name,
          url: link.url,
        });
      }
    });
  }

  return (
    <ul className="flex md:hidden lg:flex xl:flex 2xl:flex flex-start gap-5 items-center">
      {!checkIfEmpty(filterSocialMedia) &&
        filterSocialMedia.map((link, index) => (
          <li key={index}>
            <a href={link.url} target="_blank" rel="noopener noreferrer">
              {link.name === "facebook" && (
                <FaFacebookF className="text-[#fff] font-semibold text-2xl hover:cursor-pointer hover:text-blue-300" />
              )}
              {link.name === "linkedin" && (
                <FaLinkedinIn className="text-[#fff] font-semibold text-2xl hover:cursor-pointer hover:text-blue-300" />
              )}
              {link.name === "instagram" && (
                <FaInstagram className="text-[#fff] font-semibold text-3xl hover:cursor-pointer hover:text-blue-300" />
              )}
              {link.name === "twitter" && (
                <FaTwitter className="text-[#fff] font-semibold text-2xl hover:cursor-pointer hover:text-blue-300" />
              )}
              {link.name === "tiktok" && (
                <FaTiktok className="text-[#fff] font-semibold text-3xl hover:cursor-pointer hover:text-blue-300" />
              )}
            </a>
          </li>
        ))}
    </ul>
  );
};

SocialMedia.propTypes = {
  companyProfile: PropTypes.object.isRequired,
};

export default SocialMedia;

import AboutUsHeader from "@/components/about/AboutUsHeader"
import Aboutus from "@/components/about/Aboutus"
import Join from "@/components/about/Join"
import MissionVision from "@/components/about/MissionVision"

const About = () => {
  return (
    <div>
      <AboutUsHeader title="About" subTitle="About Us" />
      <Aboutus />
      <MissionVision />
      <Join />
    </div>
  )
}

export default About

export const calculateTotalPrice = (price, discount_percent) => {
    const discountAmount = price * (discount_percent / 100);
    const discountedPrice = price - discountAmount;
    return parseFloat(discountedPrice.toFixed(2));
  };
  
  export const calculateDiscountedPrice = (price, discount_percent) => {
    const discountAmount = price * (discount_percent / 100);
    return parseFloat(discountAmount.toFixed(2));
  };
  
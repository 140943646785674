import { PATH } from "@/constants/path";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";

// eslint-disable-next-line react/prop-types
const EnquiryCheckbox = ({ defaultValue }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(PATH.Terms);
  };

  return (
    <>
      <div className="flex items-start gap-2">
        <input
          type="checkbox"
          defaultChecked={defaultValue}
          {...register("enquiryCheckbox", {
            required: "This field is required",
          })}
          className="h-5 w-5 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
        />
        <label className="font-medium text-sm text-gray-700">
          By proceeding you accept our
          <button onClick={handleNavigate} className="text-red-400 px-2">
            Terms and condition
          </button>
        </label>
      </div>
      {errors.enquiryCheckbox && (
        <span className="text-red-500 text-sm">
          {errors.enquiryCheckbox.message?.toString()}
        </span>
      )}
    </>
  );
};

export default EnquiryCheckbox;

import Logo from "@/assets/images/logo.png";

export const CompanyDetails = {
  name: "Travel Nepal",
  logo: Logo,
  footer: {
    poweredBy: "Travel Nepal Pvt Ltd",
  },

  footerQuickLinks: [
    { id: 1, name: "About Us", link: "/about-us" },
    { id: 2, name: "Travel Nepal Reviews", link: "/" },
    { id: 3, name: "Travel Guid", link: "/" },
    { id: 4, name: "Data Policy", link: "/" },
    { id: 5, name: "Cookie Policy", link: "/" },
  ],
  footerActivities: [
    { id: 1, name: "Trekking", link: "/" },
    { id: 2, name: "Bungee Jump", link: "/" },
    { id: 3, name: "Rafting", link: "/" },
    { id: 4, name: "ParaGliding", link: "/" },
    { id: 5, name: "Jungle Safari", link: "/" },
  ],
  footerAddress: {
    country: "Australia",
    state: "North Lakes, QLD 4509",
    place: "43 Langer Circuit",
  },
  ContactUs: {
    number: "+61432678258",
    email: "sales@travelnepal.com.au/demo",
    TripAdvisior: {
      link: "https://www.tripadvisor.co.uk/Attraction_Review-g293890-d25439843-Reviews-Himalayan_Yog[…]n-Kathmandu_Kathmandu_Valley_Bagmati_Zone_Central_Reg.html",
    },
  },
  Branch: [
    {
      id: 1,
      country: "USA & Canada",
      name: "Dr. Prakesh Kadel",
      phone: "+14752436410",
      email: "info@hye.com.np",
    },
    {
      id: 2,
      country: "London, United Kingdom",
      name: "Debi Lal Gaire",
      phone: "+447947329103",
      email: "info@hye.com.np",
    },
    {
      id: 3,
      country: "Sydney, Australia",
      name: "Chiranjeevi Kandel",
      phone: "+61458522102",
      email: "info@hye.com.np",
    },
  ],
};

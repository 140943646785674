import { Tab } from "@headlessui/react";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";

import PlacesCarousel from "@/components/home/PlacesCarousel";
import {
  useGetAllInternationalPackage,
  useGetAllInternationalPackageCount,
} from "@/hooks/typePackage.hook";
import { getValue } from "@/utils/object";
import { ArrowLeftIcon, ArrowRightIcon } from "@radix-ui/react-icons";

const SkeletonTab = () => (
  <div className="animate-pulse bg-gradient-to-r from-gray-200 via-gray-300 to-gray-200 rounded-full px-4 py-2 w-32 h-10"></div>
);

const SkeletonPanel = () => (
  <div className="w-full h-96 bg-gradient-to-r from-gray-200 via-gray-300 to-gray-200 animate-pulse"></div>
);

const InternationalDestination = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { data: allPackageCount } = useGetAllInternationalPackageCount();
  const { data: allPackage, isLoading } = useGetAllInternationalPackage(
    allPackageCount?.[selectedIndex]?.id || ""
  );
  const [showSkeleton, setShowSkeleton] = useState(true);
  const containerRef = useRef(null);

  const scrollLeft = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: -200, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: 200, behavior: "smooth" });
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSkeleton(false);
    }, 1000); // Delay of 1000ms (1 second)

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="">
      <div className="flex flex-col">
        <Tab.Group as="div">
          <div className="w-full flex flex-col md:flex-row gap-5 justify-between px-5 md:px-16 ">
            <div className="text-xl md:text-3xl">
              <span className="font-bold">International</span>{" "}
              <span className="font-bold">Tours</span>{" "}
            </div>
            <div className="relative">
              <div className="absolute left-0 top-1/2 transform -translate-y-1/2 -translate-x-full h-full flex  px-2 ">
                <button
                  className="bg-gray-200 p-2 rounded-full w-min h-min my-auto"
                  onClick={scrollLeft}
                >
                  <ArrowLeftIcon />
                </button>
              </div>
              {/* <button
                className=" p-2 bg-gray-200 rounded-full"
                onClick={scrollLeft}
              >
              </button> */}
              <div
                ref={containerRef}
                className="max-w-3xl overflow-x-auto whitespace-nowrap scroll-mx-0 flex gap-5 md:gap-10 hide-scrollbar "
              >
                {showSkeleton ? (
                  <>
                    <SkeletonTab />
                    <SkeletonTab />
                    <SkeletonTab />
                  </>
                ) : (
                  allPackageCount?.map((item, index) => (
                    <Tab
                      key={getValue(item, "id")}
                      className={({ selected }) =>
                        `${
                          selected
                            ? "text-white bg-primary outline-none"
                            : "text-black hover:bg-primary hover:text-white"
                        } flex items-center border-[1px] rounded-full px-5 py-2 h-10 outline-none`
                      }
                      onClick={() => setSelectedIndex(index)}
                    >
                      {getValue(item, "name")}
                    </Tab>
                  ))
                )}
              </div>
              <div className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-slate-50 h-full flex  px-2 ">
                <button
                  className="bg-gray-200 p-2 rounded-full w-min h-min my-auto"
                  onClick={scrollRight}
                >
                  <ArrowRightIcon />
                </button>
              </div>
            </div>
          </div>
          <Tab.Panels className="w-full">
            {showSkeleton ? (
              <SkeletonPanel />
            ) : (
              allPackageCount?.map((item, index) => (
                <Tab.Panel key={getValue(item, "name")} className="w-full">
                  {selectedIndex === index && (
                    <>
                      {!isLoading && allPackage ? (
                        <PlacesCarousel
                          data={getValue(allPackage, "packages", [])}
                          key={getValue(item, "id")}
                        />
                      ) : (
                        <SkeletonPanel />
                      )}
                    </>
                  )}
                </Tab.Panel>
              ))
            )}
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
};

InternationalDestination.propTypes = {
  item: PropTypes.shape({
    continent_id: PropTypes.string,
    continent: PropTypes.string,
  }),
  index: PropTypes.number,
};

export default InternationalDestination;

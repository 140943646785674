import Logo from "@/assets/images/logo-dark.png";
import { AUTH_COOKIE_CONFIG } from "@/constants/common";
import { useAuthContext } from "@/hooks/contextConsumer.hook";
import { removeCookie } from "@/utils/cookie";
import { getValue } from "@/utils/object";
import { useEffect, useRef, useState } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { CiLocationOn } from "react-icons/ci";
import {
  FaKey,
  FaPhone,
  FaSuitcase,
  FaTripadvisor,
  FaUser,
} from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { MdLogout } from "react-icons/md";
import LoginModal from "../modal/LoginModal";
import SignUpModal from "../modal/SignUpModal";
import SocialMedia from "../socialMedia/SocialMedia";
import { useNavigate } from "react-router-dom";
import { PATH } from "@/constants/path";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const profiledropdownRef = useRef(null);
  const { isLoggedIn, setIsLoggedIn, companyProfile } = useAuthContext();
  const [visible, setVisible] = useState(false);
  const [signUpVisible, setSignUpVisible] = useState(false);

  const navigate = useNavigate();

  const handleClickMenu = () => {
    setIsOpen((prevValue) => !prevValue);
  };

  const handleClick = () => {
    navigate(PATH.HOME);
  };

  const handleClickOpen = () => {
    setDropDownOpen((prevValue) => !prevValue);
  };

  const handledropdownClose = (event) => {
    event.stopPropagation();
    setDropDownOpen((prevValue) => !prevValue);
  };

  const handleClose = () => {
    setDropDownOpen((prevValue) => !prevValue);
  };

  const handleNavigate = (url) => {
    window.location.href = `/${url}`;
    setDropDownOpen(false);
  };

  const onLogoutClick = () => {
    setIsLoggedIn(false);
    removeCookie(AUTH_COOKIE_CONFIG.loggedInCookie);
    removeCookie(AUTH_COOKIE_CONFIG.ACCESS_TOKEN);
    removeCookie(AUTH_COOKIE_CONFIG.REFRESH_TOKEN);
    setDropDownOpen(false);
    setIsOpen(false);
    setOpen(false);
  };

  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdownRef.current) return;

      if (
        !open ||
        (dropdownRef.current && dropdownRef.current.contains(target))
      ) {
        return;
      }
      setOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  }, [setOpen, open]);

  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!profiledropdownRef.current) return;
      if (
        !dropDownOpen ||
        (profiledropdownRef.current &&
          profiledropdownRef.current.contains(target))
      ) {
        return;
      }
      setDropDownOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  }, [setDropDownOpen, dropDownOpen]);

  return (
    <>
      <div className="relative">
        {isOpen && (
          <div
            className="fixed inset-0 bg-slate-900 bg-opacity-70 z-[30]"
            onClick={handleClickMenu}
          ></div>
        )}

        <div
          className={`relative h-16 transition-all duration-500 ease-in-out z-[20]`}
        >
          <div
            className={`fixed w-full px-5 h-16 flex justify-between items-center shadow-md z-50 ${
              isOpen ? "fixed inset-0 opacity-30 z-40" : "bg-[#1E3E64]"
            }`}
          >
            <button
              onClick={handleClick}
              className="flex items-center h-full pt-1"
            >
              <img
                src={getValue(companyProfile, "website_logo") || Logo}
                alt="logo"
                className="h-10 md:h-auto  w-auto"
              />
            </button>
            <div className="flex gap-10">
              <div className="flex gap-10">
                <ul className="flex items-center gap-5 font-medium text-sm hover:cursor-pointer text-white ">
                  {!isLoggedIn && (
                    <>
                      <li>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            setVisible(true);
                          }}
                          className="hover:text-red-400"
                        >
                          Login
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            setSignUpVisible(true);
                          }}
                          className="hover:text-red-400"
                        >
                          SignUp
                        </button>
                      </li>
                    </>
                  )}
                  {isLoggedIn && (
                    <li>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleClickOpen();
                        }}
                        className="bg-[#7fa11e] text-white w-8 h-8 rounded-full"
                      >
                        A
                      </button>
                    </li>
                  )}
                </ul>
              </div>
              <AiOutlineMenu
                size={28}
                className="text-gray-400 hover:cursor-pointer hover:text-red-500"
                onClick={handleClickMenu}
              />
            </div>
          </div>
        </div>

        {dropDownOpen && (
          <div
            ref={profiledropdownRef}
            className="fixed w-40 top-14 right-20 bg-white custom-shadow rounded-md  z-[50]"
            onClick={handledropdownClose}
          >
            <ul className="flex flex-col">
              <li
                className="flex items-center gap-2 hover:bg-[#3DB368] hover:cursor-pointer hover:text-white  rounded-t-md"
                onClick={(e) => {
                  e.stopPropagation(), handleClose();
                }}
              >
                <button
                  className="flex items-center gap-2 p-2"
                  onClick={(e) => {
                    e.stopPropagation(), handleNavigate("user/profile");
                  }}
                >
                  <FaUser className=" text-sm" />
                  <div className=" text-sm font-semibold">My Profile</div>
                </button>
              </li>
              <li
                className="flex items-center gap-2 hover:bg-[#3DB368] hover:cursor-pointer hover:text-white "
                onClick={handleClose}
              >
                <button
                  className="flex items-center gap-2 p-2"
                  onClick={(e) => {
                    e.stopPropagation(), handleNavigate("user/trips");
                  }}
                >
                  <FaSuitcase className=" text-sm" />
                  <div className=" text-sm font-semibold">My Trips</div>
                </button>
              </li>
              <li
                className="flex items-center gap-2 hover:bg-[#3DB368] hover:cursor-pointer hover:text-white  "
                onClick={handleClose}
              >
                <button
                  className="flex items-center gap-2 p-2"
                  onClick={(e) => {
                    e.stopPropagation(), handleNavigate("user/change-password");
                  }}
                >
                  <FaKey className=" text-sm" />
                  <div className=" text-sm font-semibold">Change Password</div>
                </button>
              </li>
              <li>
                <button
                  className="w-full flex items-center gap-2 hover:bg-[#3DB368] hover:cursor-pointer hover:text-white p-2   rounded-b-md"
                  onClick={() => {
                    onLogoutClick(), handleClose();
                  }}
                >
                  <MdLogout className=" text-sm font-bold" />
                  <div className=" text-sm font-semibold">Logout</div>
                </button>
              </li>
            </ul>
          </div>
        )}

        <div
          className={`flex flex-col fixed px-5 ${
            !isOpen ? "translate-x-full" : "translate-x-0"
          } bg-[#1E3E64] flex top-0 right-0 text-white h-screen w-[80%] sm:w-[50%] md:w-[25%] overflow-y-scroll opacity-100 z-[1000] transition-all ease-in-out duration-500`}
        >
          <div className="flex flex-col p-10">
            <button onClick={handleClick}>
              <img
                src={getValue(companyProfile, "website_logo") || Logo}
                alt="logo"
                height={200}
                width={200}
                className="h-auto w-full"
              />
            </button>
          </div>

          <div className="flex items-center justify-center py-5">
            <FaTripadvisor color="green" size={28} />
          </div>
          <div className="flex flex-col gap-5 items-center justify-center text-white font-semibold text-center">
            <div
              onClick={() => {
                navigate("/");
                setIsOpen(false);
              }}
              className="hover:cursor-pointer  w-full py-2"
            >
              Home
            </div>
            <div
              onClick={() => {
                navigate("/about");
                setIsOpen(false);
              }}
              className="hover:cursor-pointer  w-full py-2"
            >
              About Us
            </div>
            <div
              onClick={() => {
                navigate("/contact");
                setIsOpen(false);
              }}
              className="hover:cursor-pointer  w-full py-2"
            >
              Contact Us
            </div>

            <div className="absolute bottom-10">
              <div className="pb-10">
                <div className="text-white font-semibold text-center py-5  pb-2">
                  Get In Touch
                </div>
                <div>
                  <ul className="flex items-center justify-start flex-col gap-5 pt-5">
                    <li className="flex items-center gap-3 text-sm ">
                      <FaPhone size={16} />
                      <a
                        href={`tel:${getValue(companyProfile, "phone_number")}`}
                      >
                        {getValue(companyProfile, "phone_number")}
                      </a>
                    </li>
                    <li className="flex items-center gap-3 text-sm">
                      <IoMdMail size={16} />
                      <a
                        href={`mailto:${getValue(
                          companyProfile,
                          "email[0].email"
                        )}`}
                      >
                        {getValue(companyProfile, "email[0].email")}
                      </a>
                    </li>
                    <li className="flex items-center gap-3 text-sm">
                      <CiLocationOn size={16} />
                      <a href="#">
                        {getValue(companyProfile, "address[0].country") +
                          " " +
                          getValue(companyProfile, "address[0].city") +
                          " " +
                          getValue(companyProfile, "address[0].address")}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="text-white font-semibold text-center pt-10">
                Follow Us
              </div>

              <div className="flex items-center justify-center py-3">
                <SocialMedia
                  companyProfile={companyProfile && companyProfile}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <LoginModal
        visible={visible}
        setVisible={setVisible}
        setSignUpVisible={setSignUpVisible}
        onClose={() => setVisible(false)}
      />
      <SignUpModal
        visible={signUpVisible}
        setVisible={setSignUpVisible}
        setSignUpVisible={setSignUpVisible}
        onClose={() => setSignUpVisible(false)}
      />
    </>
  );
};

export default Navbar;

import DestinationCard from "@/components/home/DestinationCard";
import TourHeader from "@/components/tour/TourHeader";
import { useGetAllTourTypePackage } from "@/hooks/package.hook";
import Pagination from "@/lib/Pagination";
import { getValue } from "@/utils/object";
import { useState } from "react";
import { useParams } from "react-router-dom";

const TourTypes = () => {
  const { id } = useParams();
  const [currentPage, setCurrentPage] = useState(0);
  const limit = 12;

  const { data: allPackages } = useGetAllTourTypePackage({
    offset: currentPage * limit,
    limit,
  });

  const filterPackages = Array.isArray(allPackages)
    ? allPackages.filter((item) => {
        return item.name === id;
      })
    : [];

  const filteredPackage = filterPackages.flatMap((item) => item.packages || []);

  const handlePageChange = (data) => {
    const selectedPage = data.selected;
    setCurrentPage(selectedPage);
  };

  return (
    <div>
      <TourHeader title={id} />
      <div className="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
        <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-x-4 gap-y-4 md:gap-5 py-10">
          {filteredPackage.length > 0 ? (
            filteredPackage.map((place, index) => (
              <DestinationCard place={place} key={index} />
            ))
          ) : (
            <p>No packages available</p>
          )}
        </div>
      </div>
      <div className="flex justify-center items-center pb-10">
        <Pagination
          handlePageChange={handlePageChange}
          total={allPackages && getValue(allPackages, "count")}
          currentPage={currentPage}
          limit={limit}
        />
      </div>
    </div>
  );
};

export default TourTypes;

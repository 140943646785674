import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import ImageCarousel from "../common/ImageCarousel";
// import ContactDialog from "../common/ContactDialog";
import { PATH } from "@/constants/path";
import {
  calculateDiscountedPrice,
  calculateTotalPrice,
} from "@/utils/calclulatePrice";
import { getValue } from "@/utils/object";
import { Button } from "../ui/button";
import { useAuthContext } from "@/hooks/contextConsumer.hook";

function DestinationCard({ place }) {
  const navigate = useNavigate();
  const { companyProfile } = useAuthContext();

  return (
    <Card className="w-full flex flex-col justify-around h-full">
      <CardHeader>
        <ImageCarousel
          images={getValue(place, "images")}
          data={getValue(place, "top_deals")}
          id={getValue(place, "id")}
        />
      </CardHeader>
      <Link to="/">
        <CardContent>
          <div className="flex flex-row w-full justify-between text-sm py-1 pr-3 md:pr-1">
            <p className="text-slate-500 italic ">
              {getValue(place, "day_plans").length + 1}days{" "}
              {getValue(place, "day_plans").length} night
            </p>
            <p className="text-yellow-600">
              <i className="fa-solid fa-star pr-1"></i>
              4.5
            </p>
          </div>
          <CardTitle className="pb-3">{getValue(place, "title")}</CardTitle>

          <div className="flex gap-2 pt-4">
            <p className="text-sm text-slate-200">
              <span className=" bg-primary font-semibold p-1 rounded-sm text-sm">
                $
                {calculateTotalPrice(
                  getValue(place, "price"),
                  getValue(place, "discount_percent")
                ).toFixed(0)}
              </span>
            </p>
            <p className="text-sm text-slate-500 line-through">
              $
              {getValue(place, "discount_percent") === 0
                ? 0
                : calculateDiscountedPrice(
                    getValue(place, "price"),
                    getValue(place, "discount_percent")
                  ).toFixed(0)}
            </p>
          </div>
        </CardContent>
      </Link>
      <CardFooter className="flex flex-row ">
        <div className="basis-2/12 ">
          <a href={`tel:${getValue(companyProfile, "phone_number")}`}>
            <i className="fa-solid fa-phone border p-3 rounded bg-primary hover:bg-secondary  text-white "></i>
          </a>
        </div>
        <div className=" w-full m-0 px-3 py-0">
          <Button
            variant="outline"
            onClick={() => navigate(`${PATH.book}/${place.id}`)}
            className="w-full p-4 py-5 bg-primary m-0 text-white font-bold text-center hover:bg-secondary  hover:text-white"
          >
            Book Now
          </Button>
        </div>
      </CardFooter>
    </Card>
  );
}

DestinationCard.propTypes = {
  place: PropTypes.shape({
    images: PropTypes.arrayOf(PropTypes.string).isRequired,
    slug: PropTypes.string.isRequired,
    summarized_duration: PropTypes.string.isRequired,
    rating: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    itinerary_destinations: PropTypes.arrayOf(
      PropTypes.shape({
        days: PropTypes.number.isRequired,
        location: PropTypes.string.isRequired,
      })
    ),
    tags: PropTypes.arrayOf(PropTypes.string),
    currency: PropTypes.string.isRequired,
    starting_price: PropTypes.number.isRequired,
    strike_through_price: PropTypes.number,
    phone: PropTypes.string.isRequired,
    id: PropTypes.string,
    // Add more as needed
  }).isRequired,
};

export default DestinationCard;

import { get, post } from "@/api/client"; // Adjust the path according to your project structure
import { getValue } from "@/utils/object"; // Adjust the path according to your project structure
import { showErrorMessage, showSuccessMessage } from "@/utils/toast"; // Adjust the path according to your project structure
import { useMutation } from "@tanstack/react-query"; // Update to use the correct import path for react-query
import { useNavigate } from "react-router-dom";

export const useRegisterUser = () =>
  useMutation({
    mutationFn: (body) => post({ url: "api/v1/users/register", body }),
    onSuccess: (response) => {
      showSuccessMessage(getValue(response, "message"));
    },
    onError: (error) => showErrorMessage(getValue(error, "message")),
  });

export const useLoginAccount = () =>
  useMutation({
    mutationFn: (body) => post({ url: "api/v1/users/api/token/", body }),
  });

export const useVerifyAccount = (token) => {
  const route = useNavigate();
  return useMutation({
    mutationFn: () => {
      return get({ url: `api/v1/users/verify-email/${token}` });
    },
    onSuccess: (response) => {
      showSuccessMessage(getValue(response, "message"));
      route("/");
    },
    onError: (error) => showErrorMessage(getValue(error, "err")),
  });
};

export const useForgotPassword = () =>
  useMutation({
    mutationFn: (body) =>
      post({
        url: "api/v1/users/password/reset",
        body,
      }),
  });

export const useForgotPasswordConfirm = () =>
  useMutation({
    mutationFn: (body) =>
      post({
        url: "api/v1/users/password/reset/conform",
        body,
      }),
  });

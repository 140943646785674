import { Button } from "@/components/ui/button";

import { FormProvider, useForm } from "react-hook-form";
import CustomInput from "../form/custom/CustomInput";
import StartDate from "../form/date/StartDate";
import CustomTextArea from "../form/custom/CustomtextArea";
import { useCreateEnquiry } from "@/hooks/package.hook";
import { showErrorMessage } from "@/utils/toast";
import { getValue } from "@/utils/object";

function CallbackForm(id) {
  const methods = useForm();

  const { mutateAsync: createEnquiry, isPending } = useCreateEnquiry();

  const onSubmit = async (data) => {
    const resData = {
      departure_date: data?.start_date,
      fullname: data?.name,
      email: data?.email,
      contact: data?.phone,
      address: data?.address,
      message: data?.message,
    };
    try {
      await createEnquiry({ body: resData, id: id });
    } catch (err) {
      showErrorMessage(getValue(err, "message"));
    }
  };
  return (
    <div>
      <div className="grid gap-4 py-4">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-5 ">
              <CustomInput
                type="text"
                name="name"
                placeHolder="name"
                styles={"border "}
              />
              <CustomInput
                type="text"
                name="email"
                placeHolder="email"
                styles={"border "}
              />
              <CustomInput
                type="text"
                name="address"
                placeHolder="address"
                styles={"border "}
              />

              <CustomInput
                type="number"
                name="phone"
                placeHolder="phone number"
                styles={"border "}
              />
              <StartDate />
              <CustomTextArea name="message" placeHolder="Enter Message"  styles={'border '} />
            </div>
            <Button
              className="bg-primary w-full text-white mt-5"
              type="submit"
              isPending={isPending}
            >
              Submit
            </Button>
          </form>
        </FormProvider>
      </div>
    </div>
  );
}

export default CallbackForm;

import ContactMap from "@/components/contact/ContactMap"
import ContactUsForm from "@/components/contact/ContactUsForm"
import TourHeader from "@/components/tour/TourHeader"
const contact = () => {
  return (
    <div>
    <TourHeader title="Contact Us" subTitle="Contact" />
    <ContactUsForm /> 
    <ContactMap />  
    </div>
  )
}

export default contact

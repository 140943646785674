const TermsAndCondition = () => {
  return (
    <>
      <div
        className="flex px-0 md:px-0 lg:px-0 xl:px-0 2xl:px-0  relative"
        style={{
          width: "100%",
          height: "50vh",
        }}
      >
        <div
          className="absolute inset-0 opacity-100"
          style={{
            backgroundImage: "url('/banner1.jpg')",
            backgroundSize: "cover",
            backgroundPosition: "top",
            opacity: 0.9,
          }}
        ></div>
        <div className="absolute bottom-10 z-10 md:bottom-20 left-5 md:left-20 text-white leading-10">
          <p className="text-[40px] md:text-[60px] font-semibold text-shadow">
            Terms And Condition
          </p>
          <div className="flex mt-10 space-x-2">
            <a
              href="/"
              className="text-[18px] md:text-[24px] pb-2 md:pb-4 text-shadow    "
            >
              Home
            </a>
            <p>{`>>`}</p>
            <h2 className="text-[18px] md:text-[24px] pb-2 md:pb-4 text-shadow   ">
              Terms And Condition
            </h2>
          </div>
        </div>
        <div className="carrer-overlay absolute w-full  h-full"></div>
      </div>
      <div className=" pt-5 w-full h-auto">
        <div className="pt-5 px-4 mt-5">
          <div className="text-gray-700 text-lg leading-relaxed sm:px-28">
            <p className="pb-3 text-justify">
              "These are the"
              <label className="font-semibold">Terms and Conditions</label>"
              that will apply to your booking. Please read them carefully as
              they will bind you. These Terms shall constitute the entire
              agreement between the Company and the Client relating to the
              subject matter herein, and shall constitute a binding agreement.
              There is no verbal or written; representation, warranty, prior
              agreement, or description of services, other than as expressed
              herein. The contract and booking is made with, and services will
              be provided by, Glory World Holiday pvt.Ltd. By booking a Trip ,
              you agree to be bound by these Terms and Conditions that govern
              the relationship, the cancellation policy and limitations of
              liability. These Terms and Conditions affect your rights and
              designate the governing law and forum for the resolution of any
              and all disputes.
            </p>
            <p className="pt-3  font-3xl text-black font-semibold">
              INTRODUCTION
            </p>
            <p className="pb-3 pt-4 text-justify">
              <ul className="list-disc ml-5">
                <li>
                  Welcome to Glory Tour and Travels. By booking any service with
                  us, you agree to be bound by these terms and conditions.
                  Please read them carefully.
                </li>
              </ul>
            </p>
            <p className="pt-3  font-3xl text-black font-semibold">BOOKING</p>
            <p className="pb-3 pt-4 text-justify">
              <ul className="list-disc ml-5">
                <li>All bookings are subject to availability.</li>
                <li>
                  A booking is confirmed only upon receipt of a booking
                  confirmation from Glory Tour and Travels.
                </li>
                <li>
                  Any special requests must be communicated at the time of
                  booking. While we will try to accommodate these requests, we
                  cannot guarantee that all special requests will be met.
                </li>
              </ul>
            </p>
            <p className="pt-3  font-3xl text-black font-semibold">PAYMENTS</p>
            <p className="pb-3 pt-4 text-justify">
              <ul className="list-disc ml-5">
                <li>
                  A deposit is required at the time of booking to secure your
                  reservation.
                </li>
                <li> Full payment is due 7 days before the departure date.</li>
                <li> Payment can be made via mobile banking.</li>
                <li>
                  Failure to pay the balance by the due date may result in the
                  cancellation of your booking without refund.
                </li>
              </ul>
            </p>
            <p className="pt-3  font-3xl text-black font-semibold">
              CANCELLATIONS AND REFUNDS
            </p>
            <p className="pb-3 pt-4 text-justify">
              If you wish to cancel your booking, you must notify us in writing.
              <ul className="list-disc ml-5">
                Cancellation fees are as follows:
                <li>
                  More than 7 days before departure: 50% of the total cost
                </li>
                <li>
                  7 days or less before departure:30% of the total cost No
                  refunds will be given for no-shows or early departures.
                </li>
                <li>
                  {" "}
                  In the unlikely event that we need to cancel your tour, a full
                  refund will be provided.
                </li>
              </ul>
            </p>
            <p className="pt-3  font-3xl text-black font-semibold">
              CHANGES TO BOOKINGS
            </p>
            <p className="pb-3 pt-4 text-justify">
              <ul className="list-disc ml-5">
                <li>
                  Any changes to your booking must be requested in writing.
                </li>
                <li>
                  Changes are subject to availability and may incur additional
                  costs.
                </li>
                <li>
                  Name changes are permitted up to 7 days before departure for
                  an administrative fee of 5000.
                </li>
              </ul>
            </p>
            <p className="pt-3  font-3xl text-black font-semibold">
              TRAVEL INSURANCE
            </p>
            <p className="pb-3 pt-4 text-justify">
              <ul className="list-disc ml-5">
                <li>
                  We strongly recommend that you purchase comprehensive travel
                  insurance at the time of booking.
                </li>
                <li>
                  {" "}
                  Travel insurance should cover trip cancellation, medical
                  expenses, personal injury, and loss of personal belongings.
                </li>
              </ul>
            </p>
            <p className="pt-3  font-3xl text-black font-semibold">
              PASSPORTS AND VISA
            </p>
            <p className="pb-3 pt-4 text-justify">
              <ul className="list-disc ml-5">
                <li>
                  {" "}
                  It is your responsibility to ensure that you have a valid
                  passport and any necessary visas for your trip.{" "}
                </li>
                <li>
                  Glory Tour and Travels is not responsible for any issues
                  arising from incorrect or incomplete travel documentation.
                </li>
              </ul>
            </p>
            <p className="pt-3  font-3xl text-black font-semibold">
              HEALTH AND SAFTEY
            </p>
            <p className="pb-3 pt-4 text-justify">
              <ul className="list-disc ml-5">
                <li>
                  You are responsible for ensuring that you are in good health
                  to undertake the trip.
                </li>
                <li>
                  {" "}
                  Any health conditions or dietary requirements must be
                  communicated to us at the time of booking.
                </li>
                <li>
                  {" "}
                  Glory Tour and Travels is not liable for any injuries,
                  illnesses, or accidents that occur during the trip.
                </li>
              </ul>
            </p>
            <p className="pt-3  font-3xl text-black font-semibold">LAIBILITY</p>
            <p className="pb-3 pt-4 text-justify">
              <ul className="list-disc ml-5">
                <li>
                  Glory World Holidays Pvt.Ltd acts as an agent for the various
                  independent suppliers that provide accommodations,
                  transportation, sightseeing, activities, or other services
                  connected with your tour.
                </li>
                <li>
                  We are not responsible for any acts, errors, omissions,
                  representations, warranties, breaches, or negligence of any
                  such suppliers or for any personal injuries, death, property
                  damage, or other damages or expenses resulting from their
                  actions.
                </li>
              </ul>
            </p>
            <p className="pt-3  font-3xl text-black font-semibold">
              Force Majeure
            </p>
            <p className="pb-3 pt-4 text-justify">
              <ul className="list-disc ml-5">
                <li>
                  Glory World Holidays Pvt.Ltd shall not be liable for any
                  failure or delay in performing its obligations due to
                  circumstances beyond its reasonable control, including but not
                  limited to acts of God, war, strikes, natural disasters, or
                  other events of force majeure.
                </li>
              </ul>
            </p>
            <p className="pt-3  font-3xl text-black font-semibold">
              COMPLAINTS
            </p>
            <p className="pb-3 pt-4 text-justify">
              <ul className="list-disc ml-5">
                <li>
                  If you have any complaints during your trip, please inform
                  your tour guide or contact us immediately so that we can
                  address the issue.{" "}
                </li>
                <li>
                  Any complaints must be submitted in writing within [number]
                  days of the end of the trip.
                </li>
              </ul>
            </p>
            <p className="pt-3  font-3xl text-black font-semibold">
              GOVERNING LAW
            </p>
            <p className="pb-3 pt-4 text-justify">
              <ul className="list-disc ml-5">
                <li>
                  {" "}
                  These terms and conditions are governed by the laws of Nepal.
                </li>
                <li>
                  {" "}
                  Any disputes arising from these terms and conditions shall be
                  resolved in the courts of Country.
                </li>
              </ul>
            </p>
            <p className="pt-3  font-3xl text-black font-semibold">
              Amendments AMENDMENTS
            </p>
            <p className="pb-3 pt-4 text-justify">
              <ul className="list-disc ml-5">
                <li>
                  {" "}
                  Glory World Holidays Pvt.Ltd reserves the right to amend these
                  terms and conditions at any time without prior notice.
                </li>
                <li>
                  {" "}
                  The latest version of these terms and conditions will be
                  available on our website.
                </li>
              </ul>
            </p>
            <p className="pt-3  font-3xl text-black font-semibold">
              CONTACT INFORMATION
            </p>
            <p className="pb-8 pt-4 text-justify ">
              <ul className="list-disc ml-5">
                <li>
                  For any inquiries or further information, please contact us
                  at:
                </li>
                <strong>Email: </strong> www.gloryworld holidays.com <br />
                <strong>Phone: </strong>9849242423 <br />
                <strong>Address: </strong>Kathmandu-8,Pingalasthan Kathmandu,
                Nepal
              </ul>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
export default TermsAndCondition;

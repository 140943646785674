import PropTypes from "prop-types"; // Import PropTypes
import { useNavigate } from "react-router-dom";

const TourHeader = ({ title, subTitle }) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/");
  };
  return (
    <div
      className="flex px-0 md:px-0 lg:px-0 xl:px-0 2xl:px-0 relative"
      style={{
        width: "100%",
        height: "50vh",
      }}
    >
      <div
        className="absolute inset-0 opacity-100"
        style={{
          backgroundImage: "url('/banner1.jpg')",
          backgroundSize: "cover",
          backgroundPosition: "top",
          opacity: 0.9,
        }}
      ></div>
      <div className="absolute bottom-10 z-10 md:bottom-20 left-5 md:left-20 text-white leading-10">
        <p className="text-[40px] md:text-[60px] font-semibold text-shadow">
          {title ? title : "Tour"}
        </p>
        <div className="flex mt-10 space-x-2">
          <button
            onClick={handleNavigate}
            className="text-[18px] md:text-[24px] pb-2 md:pb-4 text-shadow"
          >
            {"Home"}
          </button>
          <p>{`>>`}</p>
          <h2 className="text-[18px] md:text-[24px] pb-2 md:pb-4 text-shadow">
            {title ? title : "Tour Package"}
          </h2>
        </div>
      </div>
      <div className="carrer-overlay absolute w-full h-full"></div>
    </div>
  );
};

// Define prop types for the component
TourHeader.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
};

export default TourHeader;

/* eslint-disable react/prop-types */
import logo from "@/assets/images/logo-dark.png";
import CustomInput from "@/components/form/custom/CustomInput";
import TextWithBorderCenter from "@/components/form/custom/TextWithBorderCenter";
import { AUTH_COOKIE_CONFIG } from "@/constants/common";
import { useLoginAccount } from "@/hooks/auth.hook";
import { useAuthContext } from "@/hooks/contextConsumer.hook";
import { setCookie } from "@/utils/cookie";
import { getValue } from "@/utils/object";
import { showErrorMessage, showSuccessMessage } from "@/utils/toast";
import { useRef } from "react";

import {
  FormProvider,
  useForm
} from "react-hook-form";
import { IoCloseSharp } from "react-icons/io5";
import useClickOutside from "../../hooks/clickOutside.hook";
import Transition from "../../lib/Transition";



const LoginModal = ({ visible, setVisible, onClose }) => {

  const modalContent = useRef(null);
  useClickOutside(modalContent, visible, setVisible);
  const { setIsLoggedIn } = useAuthContext();

  const { mutateAsync: loginUser } = useLoginAccount();

  const methods = useForm();

  const onSubmit = async (data) => {
    try {
      const response = await loginUser(data);
      const accessToken = response.access;
      const refreshToken = response.refresh;

      setCookie({
        cookieName: AUTH_COOKIE_CONFIG.ACCESS_TOKEN,
        value: accessToken,
        expiresIn: 1,
      });

      setCookie({
        cookieName: AUTH_COOKIE_CONFIG.REFRESH_TOKEN,
        value: refreshToken,
        expiresIn: 1,
      });
      setCookie({
        cookieName: AUTH_COOKIE_CONFIG.loggedInCookie,
        value: "true",
        expiresIn: 1,
      });
      setIsLoggedIn(true);
      showSuccessMessage(getValue(response, "message"));
      setVisible(false);
    } catch (err) {
      showErrorMessage(getValue(err, "message"));
    }
  };

  return (
    <>
      <Transition
        className="fixed inset-0 bg-slate-900 bg-opacity-70 z-50 transition-opacity"
        show={visible}
        enter="transition ease-out duration-200"
        enterStart="opacity-0"
        enterEnd="opacity-100"
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
        aria-hidden="true"
      />

      <Transition
        id={"category"}
        className="fixed inset-0 z-[100] overflow-hidden flex items-start top-20 mb-4 justify-center px-4 sm:px-6"
        role="dialog"
        aria-modal="true"
        show={visible}
        enter="transition ease-in-out duration-200"
        enterStart="opacity-0 translate-y-4"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-in-out duration-200"
        leaveStart="opacity-100 translate-y-0"
        leaveEnd="opacity-0 translate-y-4"
      >
        <div
          ref={modalContent}
          className="relative bg-white  border border-transparent dark:border-slate-700 overflow-auto w-auto  max-h-full rounded my-auto shadow-lg"
        >
          <button
            type="button"
            className="absolute right-0 bg-red-500 text-white rounded-full mr-2 mt-1"
            data-modal-toggle="add-user-modal"
            onClick={onClose}
          >
            <IoCloseSharp className="text-xl" />
          </button>
          <div className="w-full flex justify-center items-center ">
            <div className="min-w-[450px] shadow-md  pt-5 rounded-md">
              <div className="w-full flex justify-center items-center">
                <img src={logo} alt="" />
              </div>
              <TextWithBorderCenter style="text-xl font-medium px-3 py-2" text="Login with email" />

              <div className="p-5">
                <FormProvider {...methods}>
                  <form
                    onSubmit={methods.handleSubmit(onSubmit)}
                    className="flex flex-col gap-5"
                  >
                    <CustomInput
                      type="email"
                      name="email"
                      placeHolder="emailAddress"
                    />

                    <CustomInput
                      type="password"
                      name="password"
                      placeHolder="Password"
                    />

                    <button className="uppercase bg-[#3eb368] text-white font-bold text-sm py-3 rounded-md">
                      Login
                    </button>
                  </form>
                </FormProvider>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </>
  );
};




export default LoginModal;

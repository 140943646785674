/* eslint-disable react/prop-types */
import logo from "@/assets/images/logo-dark.png";
import ConfirmPassword from "@/components/form/ConfirmPassword";
import CustomInput from "@/components/form/custom/CustomInput";
import CustomSelect from "@/components/form/custom/CustomInputSelect";
import TextWithBorderCenter from "@/components/form/custom/TextWithBorderCenter";
import { useRegisterUser } from "@/hooks/auth.hook";
import { CountryList } from "@/lib/countrylist";
import { getValue } from "@/utils/object";
import { showErrorMessage } from "@/utils/toast";
import { useRef } from "react";
import {
  FormProvider,
  useForm
} from "react-hook-form";
import { IoCloseSharp } from "react-icons/io5";
import useClickOutside from "../../hooks/clickOutside.hook";
import Transition from "../../lib/Transition";



const SignUpModal = ({
  visible,
  setVisible,
  onClose,
}) => {

  const modalContent = useRef(null);
  useClickOutside(modalContent, visible, setVisible);
  const { mutateAsync: registerUser, isPending } = useRegisterUser();
  // const router = useRouter();
  const methods = useForm();

  const options = CountryList();

  const onSubmit = async (data) => {
    try {
      const resData = {
        email: data.email,
        password: data.password,
        nationality: data.nationality,
        full_name: data.full_name,
      };
      const response = await registerUser(resData);
      if (response) {
        setVisible(false);
      }
    } catch (err) {
      showErrorMessage(getValue(err, "message"));
    }
  };

  return (
    <>
      <Transition
        className="fixed inset-0 bg-slate-900 bg-opacity-70 z-50 transition-opacity"
        show={visible}
        enter="transition ease-out duration-200"
        enterStart="opacity-0"
        enterEnd="opacity-100"
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
        aria-hidden="true"
      />

      <Transition
        id={"category"}
        className="fixed inset-0 z-[100] overflow-hidden flex items-start top-20 mb-4 justify-center px-4 sm:px-6"
        role="dialog"
        aria-modal="true"
        show={visible}
        enter="transition ease-in-out duration-200"
        enterStart="opacity-0 translate-y-4"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-in-out duration-200"
        leaveStart="opacity-100 translate-y-0"
        leaveEnd="opacity-0 translate-y-4"
      >
        <div
          ref={modalContent}
          className="relative bg-white  border border-transparent dark:border-slate-700 overflow-auto w-auto  max-h-full rounded shadow-lg my-auto"
        >
          <button
            type="button"
            className="absolute right-0 bg-red-500 text-white rounded-full mr-2 mt-1"
            data-modal-toggle="add-user-modal"
            onClick={onClose}
          >
            <IoCloseSharp className="text-xl" />
          </button>
          <div className="w-full flex justify-center items-center ">
            <div className="min-w-[450px] shadow-md rounded-md pt-5">
              <div className="w-full flex justify-center items-center">
                <img src={logo} alt="" />
              </div>
              <TextWithBorderCenter style="text-xl font-medium px-3 py-2" text="Sign up with email" />

              <div className="p-5">
                <FormProvider {...methods}>
                  <form
                    onSubmit={methods.handleSubmit(onSubmit)}
                    className="flex flex-col gap-5"
                  >
                    <CustomInput
                      type="text"
                      name="full_name"
                      placeHolder="Full name"
                    />

                    <CustomSelect
                      name="nationality"
                      placeHolder="Your nationality"
                      options={options}
                    />

                    <CustomInput
                      type="email"
                      name="email"
                      placeHolder="Email address"
                    />

                    <ConfirmPassword />

                    {/* <EnquiryCheckbox /> */}

                    <button
                      className="uppercase bg-[#3eb368] text-white font-bold text-sm py-3 rounded-md"
                      disabled={isPending}
                    >
                      Sign Up
                    </button>
                  </form>
                </FormProvider>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </>
  );
};
export default SignUpModal
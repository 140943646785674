import { CiLocationOn } from "react-icons/ci";
import { LuPhone } from "react-icons/lu";
import { GoMail } from "react-icons/go";
import { MdAddCall } from "react-icons/md";
import { FaFacebookSquare } from "react-icons/fa";
import { BsInstagram } from "react-icons/bs";
import { FaTwitterSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
const ContactDetails = () => {
  return (
    <div className=''>
      <div className='max-w-md mx-auto p-8 md:p-7 lg:p-6 bg-[#007A5A] rounded-sm shadow-md'>
        <h2 className='text-white text-lg font-medium'>Contact Information : </h2>
        <div className='text-white Stext-xl font-medium pt-4'>Glory World Holidays</div>
        <div className='flex gap-3 items-center pt-5'>
            <CiLocationOn size={30} color='white' />
            <div className='text-lg font-sans text-white'>Kathmandu-8, Pingalasthan
            <br/>
            Kathmandu, Nepal
            </div>
        </div>
        <div className='flex gap-3 items-center pt-5'>
            <LuPhone size={28} color='white' />
            <div className='text-lg font-sans text-white'>+977 9849242493<br/>
            +977 9849242493
            </div>
        </div>
        <div className='flex gap-3 items-center pt-5'>
            <GoMail size={23} color='white' />
            <div className='text-lg font-sans text-white'>info@gloryworldholidays.com
            </div>
        </div>
        <div className='flex gap-3 items-center pt-5'>
            <MdAddCall size={23} color='white' />
            <div className='text-lg font-sans text-white'>Emergency Call <br/>
            +977 9849242493
            </div>
        </div>
        <div className='pt-5 flex flex-col justify-center'>
            <div className='text-white text-lg font-medium '>Follow Us</div>
            <div className='flex gap-2 items-center'>
                <div className='border rounded-md bg-blue-800 p-1 border-blue-800'><FaFacebookSquare size={18} color='white' /></div>
                <div className='border rounded-md bg-purple-600 p-1 border-purple-600'><BsInstagram size={18} color='white' /></div>
                <div className='border rounded-md bg-blue-400 p-1 border-blue-400'><FaTwitterSquare size={18} color='white'/></div>
                <div className='border rounded-md bg-blue-600 p-1 border-blue-600'><FaLinkedin size={18} color='white' /></div>
                <div className='border rounded-md bg-red-500 p-1 border-red-500'><FaYoutube size={18} color='white' /></div>
            </div>
        </div>
      </div>
    </div>
  )
}
export default ContactDetails;
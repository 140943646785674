import Navbar from "@/components/common/Navbar";
import { PATH } from "@/constants/path";
import About from "@/pages/about/About";
import VerifyEmail from "@/pages/auth/verifyEmail";
import Book from "@/pages/book/Book";
import Contact from "@/pages/contact/contact";
import DestinationDetails from "@/pages/destination/DestinationDetails";
import Home from "@/pages/home/Home";
import Terms from "@/pages/terms/Terms";
import TourTypes from "@/pages/tour-types/listTourTypes";
import TourDetails from "@/pages/tour/TourDetails";
import Tour from "@/pages/tours/Tour";
import User from "@/pages/user/user";
import { Route, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../components/common/Footer";
import Main from "./Main";

const Layout = () => {
  return (
    <div className="flex h-screen overflow-hidden font-poppins">
      {/* <SideBar setSidebarOpen={setSidebarOpen} sidebarOpen={sidebarOpen} /> */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden bg-body">
        <Navbar />
        <Main>
          <Routes>
            <Route path={PATH.HOME} element={<Home />} />
            <Route path={PATH.ABOUT} element={<About />} />
            <Route path={PATH.CONTACT} element={<Contact />} />
            <Route path={PATH.Tour} element={<Tour />} />
            <Route path={PATH.Terms} element={<Terms />} />
            <Route path={`${PATH.book}/:id`} element={<Book />} />
            <Route
              path={`${PATH.DESTINATIONDETAILS}:id`}
              element={<DestinationDetails />}
            />
            <Route path={`${PATH.TOURDETAILS}:id`} element={<TourDetails />} />
            <Route path={`${PATH.tourType}/:id`} element={<TourTypes />} />
            <Route path={`${PATH.verifyEmail}`} element={<VerifyEmail />} />
            <Route path={`${PATH.user}/:id`} element={<User />} />
          </Routes>
        </Main>
        <Footer />
      </div>
    </div>
  );
};

export default Layout;

import { Fragment, useState, useEffect, useCallback } from "react";
import { Tab } from "@headlessui/react";
import { FormProvider, useForm } from "react-hook-form";
import CustomSelect from "@/components/form/custom/CustomInputSelect";
import { customOption } from "@/constants/cutsom";
import CustomInput from "@/components/form/custom/CustomInput";
import { CountryList } from "@/lib/countrylist";
import CustomTextArea from "@/components/form/custom/CustomtextArea";
import PassportIssueDate from "@/components/form/PassportIssueDate";
import PassportExpiryDate from "@/components/form/PassportExpiryDate";
import { showErrorMessage, showSuccessMessage } from "@/utils/toast";
import { getValue } from "@/utils/object";
import {
  useGetUserDetails,
  usePasswordReset,
  useUpdateUserDetails,
} from "@/hooks/user.hook";
import { CompanyDetails } from "@/constants/companyDetails";
import DateOfBirth from "@/components/form/DateOfBirth";
import CartDetailsUser from "@/components/tour/CartDetailUser";
import { useAuthContext } from "@/hooks/contextConsumer.hook";
import { checkIfEmpty } from "@/utils/validation";
import { getCookie } from "@/utils/cookie";
import { AUTH_COOKIE_CONFIG } from "@/constants/common";
import { useNavigate, useParams } from "react-router-dom";
import getCountriesCodeList from "@/utils/countryList";

const User = () => {
  console.log("user page");
  const methods = useForm();
  const method = useForm();
  const options = CountryList();
  const { data: userDetails } = useGetUserDetails();
  const { mutateAsync: updatePassword } = usePasswordReset();
  const { mutateAsync: updateUser } = useUpdateUserDetails();
  const { countriesDailCode } = getCountriesCodeList();

  const { isLoggedIn } = useAuthContext();
  const cookie = getCookie(AUTH_COOKIE_CONFIG.ACCESS_TOKEN);

  const router = useNavigate();
  const { id } = useParams();

  console.log({ id }, "id");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const tabMap = {
    profile: 0,
    "change-password": 1,
    trips: 2,
  };

  const getIdIndex = useCallback((id) => {
    if (typeof id === "string") {
      return tabMap[id] ?? 0;
    } else if (Array.isArray(id)) {
      return tabMap[id[0]] ?? 0;
    }
    return 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [selectedIndex, setSelectedIndex] = useState(() => getIdIndex(id));

  useEffect(() => {
    setSelectedIndex(getIdIndex(id));
  }, [id, getIdIndex]);

  useEffect(() => {
    if (checkIfEmpty(cookie) && !isLoggedIn) {
      router("/");
    }
  }, [isLoggedIn, router, cookie]);

  if (!isLoggedIn) {
    return null;
  }

  const packageDetails =
    userDetails && userDetails.map((item) => item.user_inquiry_details);

  const dateOfBirth = getValue(
    userDetails,
    "[0].user_personal_detail.date_of_birth"
  );

  const dateOfPassportExpiration = getValue(
    userDetails,
    "[0].user_personal_detail.passport_expiration"
  );

  const dateOfPassportIssued = getValue(
    userDetails,
    "[0].user_personal_detail.passport_issued_date"
  );
  const dateObj = new Date(dateOfBirth);
  const birth_year = dateObj.getFullYear();
  const birth_month = dateObj.getMonth() + 1;
  const birth_day = dateObj.getDate();

  const dateObjPassportExpiration = new Date(dateOfPassportExpiration);
  const expiration_year = dateObjPassportExpiration.getFullYear();
  const expiration_month = dateObjPassportExpiration.getMonth() + 1;
  const expiration_day = dateObjPassportExpiration.getDate();

  const dateObjPassportIssued = new Date(dateOfPassportIssued);
  const issued_year = dateObjPassportIssued.getFullYear();
  const issued_month = dateObjPassportIssued.getMonth() + 1;
  const issued_day = dateObjPassportIssued.getDate();

  const storeCountryCode = [];

  for (const [key, value] of Object.entries(countriesDailCode)) {
    const formatTourType = {
      label: value,
      value: key,
      country: key,
    };
    storeCountryCode.push(formatTourType);
  }

  const onSubmit = async (data) => {
    const passport_issued_date = `${getValue(
      data,
      "passportSelectedYear"
    )}-${getValue(data, "passportSelectedMonth")}-${getValue(
      data,
      "passportSelectedDay"
    )}`;

    const passport_expiration_date = `${getValue(
      data,
      "passportExpiredYear"
    )}-${getValue(data, "passportExpiredMonth")}-${getValue(
      data,
      "passportExpiredDay"
    )}`;

    const date_of_birth = `${getValue(data, "dateOfBirthYear")}-${getValue(
      data,
      "dateOfBirthMonth"
    )}-${getValue(data, "dateOfBirthDay")}`;

    try {
      const resData = {
        fullname: getValue(data, "fullname"),
        title: getValue(data, "title"),
        occupation: getValue(data, "occupation"),
        nationality: getValue(data, "nationality"),
        // email: getValue(data, "email"),
        country_code: getValue(data, "country_code"),
        passport_number: getValue(data, "passport-number"),
        date_of_birth: date_of_birth,
        place_of_issue: getValue(data, "place_of_issue"),
        passport_issued_date: passport_issued_date,
        passport_expiration: passport_expiration_date,
        phone_number: getValue(data, "phone_number"),
        short_bio: getValue(data, "short_bio"),
      };
      await updateUser(resData);
    } catch (err) {
      showErrorMessage(getValue(err, "message"));
    }
  };

  const onSubmitResetPassword = async (data) => {
    try {
      const resData = {
        old_password: data.old_password,
        new_password: data.new_password,
      };
      if (resData.new_password != data.retype_password) {
        showErrorMessage("Password are not matched ");
        return;
      }
      const response = await updatePassword(resData);
      showSuccessMessage(getValue(response, "message"));
    } catch (err) {
      showErrorMessage(getValue(err, "message"));
    }
  };

  return (
    <div className=" py-10 bg-[#F2F2F2]">
      <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
        <div className="flex flex-col bg-[#F2F2F2]">
          <Tab.List className="px-5 md:px-20 lg:px-28 flex flex-row items-start md:items-center bg-white border-gray-300 border-t-[1px]">
            <div className="flex items-center">
              <Tab as={Fragment}>
                {({ selected }) => (
                  <button
                    className={`${
                      selected ? "text-red-500 bg-[#F2F2F2]" : " text-black"
                    } text-sm text-start p-3 hover:text-red-400 hover:cursor-pointer outline-none border-gray-300 border-x-[1px]`}
                  >
                    My Profile
                  </button>
                )}
              </Tab>
            </div>
            <div className="flex items-center">
              <Tab as={Fragment}>
                {({ selected }) => (
                  <button
                    className={`${
                      selected ? "text-red-500 bg-[#F2F2F2]" : " text-black"
                    } text-sm text-start p-3 hover:text-red-400 hover:cursor-pointer outline-none border-gray-300`}
                  >
                    Change Password
                  </button>
                )}
              </Tab>
            </div>
            <div className="flex items-center">
              <Tab as={Fragment}>
                {({ selected }) => (
                  <button
                    className={`${
                      selected ? "text-red-500 bg-[#F2F2F2]" : " text-black"
                    } text-sm text-start p-3 hover:text-red-400 hover:cursor-pointer outline-none border-gray-300 border-x-[1px]`}
                  >
                    Your Packages
                  </button>
                )}
              </Tab>
            </div>
          </Tab.List>

          <Tab.Panels>
            <Tab.Panel>
              <FormProvider {...methods}>
                <form
                  onSubmit={methods.handleSubmit(onSubmit)}
                  className="flex flex-col gap-5 my-5"
                >
                  <div className="flex flex-col mx-5 md:mx-20 lg:mx-28 gap-3 my-5  shadow bg-white rounded ">
                    <div className="flex flex-col gap-3 border-b-[1px] p-5">
                      <h1 className="font-bold uppercase">
                        Your {CompanyDetails.name} Account Details
                      </h1>

                      <h2 className="text-[#909090] text-sm ">
                        We only display your profile picture, name, languages,
                        nationality, occupation, website, and bio to other
                        users. All other details are private and will make it
                        easier for you to make tour bookings on{" "}
                        {CompanyDetails.name}.
                      </h2>
                    </div>

                    <div className="flex flex-col gap-5">
                      <div className="flex flex-col gap-5 p-5">
                        <div>
                          <div className="font-semibold pb-2 text-sm">
                            Full Name <span className="text-red-500">*</span>
                          </div>
                          <div className="flex flex-col md:flex-row gap-5 ">
                            <div className="w-full flex flex-col gap-5 md:gap-2 md:flex-row">
                              <CustomSelect
                                placeHolder="Title"
                                name="title"
                                style={false}
                                options={customOption}
                                defaultValue={getValue(
                                  userDetails,
                                  "[0].user_personal_detail.title"
                                )}
                              />
                            </div>
                            <CustomInput
                              type="text"
                              placeHolder="Full Name"
                              name="fullname"
                              defaultValue={getValue(
                                userDetails,
                                "[0].full_name"
                              )}
                            />
                          </div>
                        </div>

                        <div>
                          <div className="font-semibold pb-2 text-sm">
                            Date of Birth / Nationality{" "}
                            <span className="text-red-500">*</span>
                          </div>
                          <div className="w-full flex flex-col md:flex-row  gap-5 md:gap-5">
                            <div className="w-full md:w-1/2">
                              <DateOfBirth
                                required={false}
                                birth_year={birth_year}
                                birth_day={birth_day}
                                birth_month={birth_month}
                              />
                            </div>

                            <div className="w-full md:w-1/2">
                              <CustomSelect
                                placeHolder="Select Nationality"
                                name="nationality"
                                style={true}
                                options={options}
                                defaultValue={getValue(
                                  userDetails,
                                  "[0].nationality_name"
                                )}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="flex flex-col md:flex-row  justify-between  gap-5">
                          <div className="w-full">
                            <div className="font-semibold pb-2 text-sm">
                              Short Bio
                            </div>

                            <div className="w-full">
                              <CustomTextArea
                                placeHolder="Enter Your bio"
                                name="short_bio"
                                defaultValue={getValue(
                                  userDetails,
                                  "[0].user_personal_detail.short_bio"
                                )}
                              />
                            </div>
                          </div>

                          <div className="w-full">
                            <div className="font-semibold pb-2 text-sm">
                              occupation
                            </div>

                            <div className="w-full">
                              <CustomInput
                                type="text"
                                placeHolder="Enter Your occupation"
                                name="occupation"
                                defaultValue={getValue(
                                  userDetails,
                                  "[0].user_personal_detail.occupation"
                                )}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col mx-5 md:mx-20 lg:mx-28 gap-3 mb-5   shadow bg-white rounded ">
                    <div className="flex flex-col gap-3 border-b-[1px] p-5">
                      <h1 className="font-bold uppercase">
                        Your Contact Details
                      </h1>

                      <h2 className="text-[#909090] text-sm">
                        Your contact details are only shared with the tour
                        provider you book with.
                      </h2>
                    </div>

                    <div className="flex flex-col gap-5">
                      <div className="flex flex-col gap-5 p-5">
                        <div>
                          <div className="font-semibold pb-2 text-sm">
                            Email Address{" "}
                            <span className="text-red-500">*</span>
                          </div>
                          <div className="flex flex-col md:flex-row gap-5 ">
                            <div className="w-full flex flex-col gap-5 md:gap-2 md:flex-row">
                              <CustomInput
                                type="email"
                                placeHolder="Email Address"
                                name="email"
                                defaultValue={getValue(
                                  userDetails,
                                  "[0].email"
                                )}
                              />
                            </div>
                          </div>
                        </div>

                        <div>
                          <div className="font-semibold pb-2 text-sm">
                            Contact Number
                          </div>
                          <div className="flex flex-col md:flex-row gap-5 md:gap-5">
                            <CustomSelect
                              placeHolder="Country Code"
                              name="country_code"
                              style={true}
                              options={storeCountryCode}
                              required={true}
                              defaultValue={getValue(
                                userDetails,
                                "[0].user_personal_detail.country_code"
                              )}
                            />
                            <CustomInput
                              type="text"
                              placeHolder="Mobile Number"
                              name="phone_number"
                              defaultValue={getValue(
                                userDetails,
                                "[0].phone_number"
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col mx-5 md:mx-20 lg:mx-28 gap-3 mb-5    shadow bg-white rounded ">
                    <div className="flex flex-col gap-3 border-b-[1px] p-5">
                      <h1 className="font-bold uppercase">
                        YOUR PASSPORT DETAILS
                      </h1>

                      <h2 className="text-[#909090] text-sm">
                        Passport details are only shared with the tour provider
                        you book with.
                      </h2>
                    </div>

                    <div className="flex flex-col gap-5">
                      <div className="flex flex-col gap-5 p-5">
                        <div>
                          <div className="font-semibold pb-2 text-sm">
                            Passport
                          </div>
                          <div className="flex flex-col md:flex-row gap-5 md:gap-5">
                            <CustomInput
                              type="text"
                              placeHolder="Passport number"
                              name="passport-number"
                              required={false}
                              defaultValue={getValue(
                                userDetails,
                                "[0].user_personal_detail.passport_number"
                              )}
                            />
                            <CustomSelect
                              placeHolder="Select place of issue"
                              name="place_of_issue"
                              style={true}
                              options={options}
                              required={false}
                              defaultValue={getValue(
                                userDetails,
                                "[0].user_personal_detail.place_of_issue"
                              )}
                            />
                          </div>
                        </div>

                        <div className="w-full flex flex-col md:flex-row gap-5">
                          <div className="w-full flex flex-col ">
                            <div className="font-semibold pb-2 text-sm">
                              Passport Issue Date
                            </div>
                            <div className="w-full">
                              <PassportIssueDate
                                required={false}
                                issue_day={issued_day}
                                issue_month={issued_month}
                                issue_year={issued_year}
                              />
                            </div>
                          </div>

                          <div className="w-full flex flex-col ">
                            <div className="font-semibold pb-2 text-sm">
                              Passport Expiration Date
                            </div>
                            <div className="w-full">
                              <PassportExpiryDate
                                required={false}
                                expiry_day={expiration_day}
                                expiry_month={expiration_month}
                                expiry_year={expiration_year}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col mx-5 md:mx-20 lg:mx-28 gap-3 mb-5   shadow bg-white rounded ">
                    <div className="flex flex-col gap-10 border-b-[1px] p-5">
                      <h1 className="font-bold uppercase">
                        Your Travels Credit
                      </h1>

                      <h2 className="text-[#909090] text-sm">
                        You haven&apos;t earned any travel credits yet. Travel
                        credits are earned when buying tours on{" "}
                        {CompanyDetails.name}. Any travel credits earned will be
                        provided upon the tour starting date.
                      </h2>
                    </div>
                  </div>

                  {/* <div className="flex flex-col mx-5 md:mx-20 lg:mx-28 gap-3 mb-5   shadow bg-white rounded ">
                    <div className="flex flex-col gap-10 border-b-[1px] p-5">
                      <h1 className="font-bold uppercase">
                        Email Subscription
                      </h1>

                      <div className="flex flex-col md:flex-row justify-between gap-3">
                        <h2 className="text-[#909090] text-sm">
                          You have subscribed to our email newsletters. You will
                          get notified about travel insights and travel deals on
                          {CompanyDetails.name}.
                        </h2>
                        <button
                          type="button"
                          className="py-3 px-5 border-[#3DB367] border-[0.1px] text-sm text-red-500
                           hover:bg-[#3DB367]
                            hover:text-white rounded-md "
                        >
                          UnSubscribe
                        </button>
                      </div>
                    </div>
                  </div> */}

                  <div className="flex flex-col md:flex-row justify-end gap-3 mx-5 md:mx-20 lg:mx-28">
                    <button className="w-full md:w-auto py-3 px-5 bg-white border-[0.1px] border-[#3DB367] text-sm text-[#3DB367] rounded-md ">
                      Export Profile
                    </button>
                    <button
                      className="w-full md:w-auto py-3 px-5 border-[#3DB367] border-[0.1px] text-sm text-white
                           bg-[#3DB367]
                            rounded-md "
                    >
                      Save Profile
                    </button>
                  </div>
                </form>
              </FormProvider>
            </Tab.Panel>
            <Tab.Panel>
              <FormProvider {...method}>
                <form
                  onSubmit={method.handleSubmit(onSubmitResetPassword)}
                  className="flex flex-col gap-5 my-5"
                >
                  <div className="flex flex-col mx-5 md:mx-20 lg:mx-28 gap-3 my-5  ">
                    <div className="flex flex-col gap-5">
                      <div className="flex flex-col gap-5 p-0 md:p-5">
                        <div className="w-full md:w-1/2">
                          <div className="font-semibold pb-2 text-sm">
                            Old Password <span className="text-red-500">*</span>
                          </div>
                          <div className="flex flex-col md:flex-row gap-5 ">
                            <div className="w-full flex flex-col gap-5 md:gap-2 md:flex-row">
                              <CustomInput
                                type="password"
                                placeHolder="Old Password"
                                name="old_password"
                                required={true}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="w-full md:w-1/2">
                          <div className="font-semibold pb-2 text-sm">
                            New Password
                            <span className="text-red-500">*</span>
                          </div>
                          <div className="flex flex-col md:flex-row gap-5 ">
                            <div className="w-full flex flex-col gap-5 md:gap-2 md:flex-row">
                              <CustomInput
                                type="password"
                                placeHolder="New Password"
                                name="new_password"
                                required={true}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="w-full md:w-1/2">
                          <div className="font-semibold pb-2 text-sm">
                            Retype Password
                            <span className="text-red-500">*</span>
                          </div>
                          <div className="flex flex-col md:flex-row gap-5 ">
                            <div className="w-full flex flex-col gap-5 md:gap-2 md:flex-row">
                              <CustomInput
                                type="password"
                                placeHolder="Retype Password"
                                name="retype_password"
                                required={true}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="px-0 md:px-5">
                        <button
                          className="w-full md:w-auto py-3 px-5 border-[#3DB367] border-[0.1px] text-sm text-white
                           bg-[#3DB367] "
                        >
                          Change Password
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </FormProvider>
            </Tab.Panel>
            <Tab.Panel>
              <div className="flex flex-col mx-5 md:mx-20 lg:mx-28 gap-3 my-5  shadow bg-white rounded">
                <div className="flex flex-col gap-3 border-b-[1px] p-5">
                  <h1 className="font-bold uppercase">Your Package Details</h1>
                </div>
                <div className="mx-5 md:mx-20 lg:mx-28 gap-3 my-20">
                  <CartDetailsUser packageDetails={packageDetails} />
                </div>
              </div>
            </Tab.Panel>
          </Tab.Panels>
        </div>
      </Tab.Group>
    </div>
  );
};

export default User;

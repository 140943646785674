import image from "../../assets/images/Belgium.png";
// import pattern from "../assets/img/login/pattern.png";
// import thunder from "../assets/img/login/thunderbolt.png";
import { useForm } from "react-hook-form";
import { FiLock, FiUser } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import DarkLogo from "../../assets/images/logo-dark.png";
import Logo from "../../assets/images/logo.png";
// import Input from "../components/custom/Input";



const Login = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    navigate("/");
    console.log(data);

  // Add your login logic here
  };

  return (
    <>
      {/* <div className="bg-primaryDark flex items-center justify-center">
        <img className="w-96" src={Logo} alt="logo" />
      </div> */}
      <div className="w-full bg- min-h-screen flex  flex-col lg:flex-row font-inter">
        {/* Left Section: Login Form */}
        <div
          className={`flex-1 flex flex-col items-center justify-center text-black md:bg-surface order-2`}
          style={
            {
              // backgroundImage: window.innerWidth < 700 ? `url(${pattern})` : "",
            }
          }
        >
          <img className="mb-10" src={DarkLogo} alt="" />

          <p className="md:block text-3xl text-white md:text-black pb-4 font-bold">
            Welcome back
          </p>
          <p className="hidden md:block text-xs ">
            Login with your Credintials
          </p>

          <form
            onSubmit={handleSubmit(onSubmit)}
            className="p-8 lg:w-8/12  mx-auto grid gap-4"
          >
            <div>
              <label className="text-sm flex items-center bg-[#F0EDFFCC] min-h-10 pl-2 py-0 rounded-md">
                <FiUser className="mr-2" />
                <input
                  type="text"
                  placeholder="Username"
                  {...register("uname", { required: true })}
                  className="bg-transparent  w-full h-full p-2 m-0 rounded-md outline-none placeholder-text-black placeholder:text-md  md:h-auto text-md focus-visible:bg-transparent border-0 focus:ring-0"
                />
              </label>
              {errors.uname && (
                <p className="text-red-500 text-xs">* Username is required</p>
              )}
            </div>
            <div>
              <label className="text-sm min-h-10 flex items-center bg-[#F0EDFFCC] px-2 py-0 rounded-md">
                <FiLock className="mr-2" />
                <input
                  type="password"
                  placeholder="Password"
                  {...register("password", { required: true })}
                  className="bg-transparent w-full p-2 mt-2 rounded-md outline-none placeholder-text-black placeholder:text-md h-10 md:h-auto text-md border-0 focus:ring-0"
                />
              </label>

              {errors.password && (
                <p className="text-red-500 text-xs">* Password is required</p>
              )}
            </div>
            <button
              type="submit"
              className="bg-primary text-white p-2 rounded-md w-fit m-auto px-3  md:w-full mt-4"
            >
              Sign In
            </button>

            <p>
              Don&apos;t have an account?{" "}
              <button
                onClick={() => navigate("/signup")}
                className="text-secondary"
              >
                Sign up now
              </button>
            </p>
          </form>
        </div>

        {/* Right Section: Additional Content */}
        <div
          style={{
            // backgroundImage: `url(${pattern})`,
            backgroundSize: "cover",
          }}
          className="hidden  flex-col flex-1 bg-primary self-stretch md:flex items-center justify-center text-white overflow-hidden"
        >
          <div className="w-full pl-48">
            <div className="w-">
              <img
                src={Logo}
                className="h-full overflow-visible object-cover w-40"
                alt="Image"
              />
              <p className="text-left text-xs pl-3">
                Manage your project and team in easy way
              </p>
            </div>
            <div className=" backdrop-blur-sm p-8 bg-surface/20 shadow-md shadow-surface/20 rounded-2xl max-w-sm min-h-[70%]">
              <img
                src={image}
                className="h-full overflow-visible object-cover "
                alt="Image"
              />

              {/* <p className="text-3xl font-bold pr-20">
              Empowering Your Workforce, Simplifying Your HR
            </p> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;

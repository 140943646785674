import ConfirmEmailAddress from "@/components/form/ConfirmEmailAddress";
import PassportExpiryDate from "@/components/form/PassportExpiryDate";
import PassportIssueDate from "@/components/form/PassportIssueDate";
import CustomInput from "@/components/form/custom/CustomInput";
import CustomSelect from "@/components/form/custom/CustomInputSelect";
import { CountryList } from "@/lib/countrylist";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import EnquiryCheckbox from "@/components/form/EnquiryCheckbox";
import { customOption } from "@/constants/cutsom";
import { getCountriesCodeList } from "@/utils/countryList";
import { getValue } from "@/utils/object";
import { useAuthContext } from "@/hooks/contextConsumer.hook";
import DateOfBirth from "@/components/form/DateOfBirth";
import { useGetSinglePackageDetails } from "@/hooks/package.hook";
import { calculateTotalPrice } from "@/utils/calclulatePrice";
import { useCreateBooking } from "@/hooks/booking.hook";
import { useNavigate, useParams } from "react-router-dom";
import { showErrorMessage } from "@/utils/toast";
import BookingDatePicker from "@/components/form/date/BookingDatePicker";

const Book = () => {
  const [traveler, setTraveler] = useState(1);
  const [isPaymentOption, setIsPaymentOption] = useState(false);
  const methods = useForm();
  const { countriesDailCode } = getCountriesCodeList();
  const [bookingDate, setBookingDate] = useState();
  const { bookingDetails } = useAuthContext();

  const navigate = useNavigate();
  const { id } = useParams();
  const { data: singlePackageDetail } = useGetSinglePackageDetails(id);

  const { mutateAsync: createBooking, isPending } = useCreateBooking();

  const price = calculateTotalPrice(
    getValue(singlePackageDetail, "price"),
    getValue(singlePackageDetail, "discount_percent")
  );

  useEffect(() => {
    setTraveler(getValue(bookingDetails, "traveler") || 1);
    setBookingDate(getValue(bookingDetails, "bookingDate"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingDetails]);

  const storeCountryCode = [];

  for (const [key, value] of Object.entries(countriesDailCode)) {
    const formatTourType = {
      label: value,
      value: key,
      country: key,
    };
    storeCountryCode.push(formatTourType);
  }

  const handleAdd = () => {
    setTraveler((prevVal) => prevVal + 1);
  };

  const handleSubtract = () => {
    if (traveler > 1) {
      setTraveler((prevVal) => prevVal - 1);
    }
  };

  const handlePaymentMethodChange = (event) => {
    if (event.target.id === "cash-on-delivery") {
      setIsPaymentOption(false);
    } else if (event.target.id === "card-option") {
      setIsPaymentOption(true);
    }
  };

  const formatDateToDDMMYY = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear());
    return `${year}-${month}-${day}`;
  };

  const onSubmit = async (data) => {
    const passport_issued_date = `${getValue(
      data,
      "passportSelectedYear"
    )}-${getValue(data, "passportSelectedMonth")}-${getValue(
      data,
      "passportSelectedDay"
    )}`;

    const passport_expiration_date = `${getValue(
      data,
      "passportExpiredYear"
    )}-${getValue(data, "passportExpiredMonth")}-${getValue(
      data,
      "passportExpiredDay"
    )}`;

    const date_of_birth = `${getValue(data, "dateOfBirthYear")}-${getValue(
      data,
      "dateOfBirthMonth"
    )}-${getValue(data, "dateOfBirthDay")}`;

    try {
      const resData = {
        number_of_travellers: traveler,
        travel_date: formatDateToDDMMYY(getValue(data, "start_date")),
        amount: (traveler * price).toFixed(2),
        user_booking_detail: [
          {
            passport_issue_date: passport_issued_date,
            passport_expiry_date: passport_expiration_date,
            date_of_birth: date_of_birth,
            country: getValue(data, "nationality"),
            title: getValue(data, "title"),
            first_name: getValue(data, "firstName"),
            last_name: getValue(data, "lastName"),
            email: getValue(data, "emailAddress"),
            passport_number: getValue(data, "passport_number"),
            place_of_issue: getValue(data, "passportPlaceOfIssue"),
            country_code: getValue(data, "country_code"),
            contact_number: getValue(data, "mobile_number"),
          },
        ],
      };

      console.log(resData.travel_date, "date");
      const response = await createBooking({ id: id, body: resData });
      window.location.href = response.url;
    } catch (err) {
      showErrorMessage(getValue(err, "message"));
    }
  };

  const options = CountryList();

  return (
    <div className="px-5 md:px-20 lg:px-28 py-5">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-5 pt-10">
            <div className="flex items-center gap-3">
              <div className="bg-[#3DB368] flex items-center justify-center h-8 w-8 rounded-full text-lg text-white">
                1
              </div>
              <div className="font-semibold text-xl">Travelers</div>
            </div>
            <div className="w-full flex flex-col md:flex-row gap-5">
              <div className="w-full flex justify-between items-center border-[1px] p-4 rounded-md">
                <div className="font-semibold text-sm">Number of Traveler</div>

                <div className="flex items-center gap-5 rounded-full border-[1px]">
                  <button
                    type="button"
                    className="flex items-center justify-center border-2 border-[#CACACA] text-[#CACACA] rounded-full h-10 w-10"
                    onClick={handleSubtract}
                  >
                    -
                  </button>
                  <div>{traveler}</div>
                  <button
                    type="button"
                    className="flex items-center justify-center border-2 border-red-500 text-red-500 rounded-full h-10 w-10"
                    onClick={handleAdd}
                  >
                    +
                  </button>
                </div>
              </div>
              <div className="w-full flex justify-between items-center border-[1px] p-4 rounded-md">
                <div className="font-semibold text-sm">Pick Your Date</div>

                <div className="w-auto flex items-center gap-5">
                  <BookingDatePicker defaultValue={bookingDate} required />
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-5 pt-10">
            <div className="flex items-center gap-3">
              <div className="bg-[#3DB368] flex items-center justify-center h-8 w-8 rounded-full text-lg text-white">
                2
              </div>
              <div className="font-semibold text-xl">Your Details</div>
            </div>

            <div className="flex flex-col gap-5 border-[1px] rounded-md px-5 py-10">
              <div>
                <div className="font-semibold pb-2 text-sm">
                  Full Name <span className="text-red-500">*</span>
                </div>
                <div className="flex flex-col md:flex-row gap-5">
                  <div className="w-full flex flex-col gap-5 md:gap-2 md:flex-row">
                    <CustomSelect
                      placeHolder="Title"
                      name="title"
                      style={false}
                      options={customOption}
                      required={true}
                    />
                    <CustomInput
                      type="text"
                      placeHolder="First Name"
                      name="firstName"
                      required={true}
                    />
                  </div>
                  <CustomInput
                    type="text"
                    placeHolder="Last Name"
                    name="lastName"
                    required={true}
                  />
                </div>
              </div>

              <div>
                <div className="font-semibold pb-2 text-sm">
                  Email Address <span className="text-red-500">*</span>
                </div>
                <ConfirmEmailAddress />
              </div>

              <div>
                <div className="font-semibold pb-2 text-sm">
                  Date of Birth / Nationality{" "}
                  <span className="text-red-500">*</span>
                </div>
                <div className="w-full flex flex-col md:flex-row gap-5 md:gap-5">
                  <div className="w-full  flex flex-col md:flex-row gap-5">
                    <div className="w-full">
                      <DateOfBirth />
                    </div>
                    <div className="w-full">
                      <CustomSelect
                        placeHolder="Country"
                        name="nationality"
                        style={false}
                        options={options}
                        required={true}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div>
                {/* <div className="font-semibold pb-2 text-sm">
                  Passport Details <span className="text-red-500">*</span>
                </div> */}
                <div className="w-full flex flex-col gap-5">
                  <div className="w-full  flex flex-col md:flex-row gap-5">
                    <div className="w-full">
                      <div className="font-semibold pb-2 text-sm">
                        Passport Issue Date{" "}
                        <span className="text-red-500">*</span>
                      </div>
                      <PassportIssueDate />
                    </div>

                    <div className="w-full">
                      <div className="font-semibold pb-2 text-sm">
                        Passport Expiry Date{" "}
                        <span className="text-red-500">*</span>
                      </div>
                      <PassportExpiryDate />
                    </div>
                  </div>

                  <div className="flex flex-col md:flex-row gap-5">
                    <div className="w-full">
                      <div className="font-semibold pb-2 text-sm">
                        Passport Number <span className="text-red-500">*</span>
                      </div>
                      <CustomInput
                        type="text"
                        placeHolder="Passport Number"
                        name="passport_number"
                        required={true}
                      />
                    </div>

                    <div className="w-full">
                      <div className="font-semibold pb-2 text-sm">
                        Place of Issue <span className="text-red-500">*</span>
                      </div>
                      <CustomInput
                        type="text"
                        placeHolder="Place of Issue"
                        name="passportPlaceOfIssue"
                        required={true}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className="font-semibold pb-2 text-sm">
                  Contact Details <span className="text-red-500">*</span>
                </div>
                <div className="flex flex-col md:flex-row gap-5">
                  <CustomSelect
                    placeHolder="Country Code"
                    name="country_code"
                    style={false}
                    options={storeCountryCode}
                    required={true}
                  />
                  <CustomInput
                    type="text"
                    placeHolder="Mobile Number"
                    name="mobile_number"
                    required={true}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-5 pt-10">
            <div className="flex items-center gap-3">
              <div className="bg-[#3DB368] flex items-center justify-center h-8 w-8 rounded-full text-lg text-white">
                3
              </div>
              <div className="font-semibold text-xl">Payment Method</div>
            </div>

            <div className="flex flex-col gap-5 border-[1px] rounded-md px-5 py-10">
              <div>
                <div className="flex flex-col gap-5 md:flex-row">
                  <EnquiryCheckbox
                    title="Card Option"
                    value="card-option"
                    onChange={handlePaymentMethodChange}
                  />
                </div>
                {isPaymentOption && (
                  <div className="flex flex-col gap-5 pt-5">
                    <div>
                      <div className="font-semibold pb-2 text-sm">
                        Payment Details <span className="text-red-500">*</span>
                      </div>
                      <div className="flex flex-col md:flex-row gap-5">
                        <CustomInput
                          type="text"
                          placeHolder="Name on Card"
                          name="name_on_card"
                          required={true}
                        />
                        <CustomInput
                          type="text"
                          placeHolder="Card Number"
                          name="card_number"
                          required={true}
                        />
                      </div>
                      <div className="flex flex-col md:flex-row gap-5 pt-5">
                        <div className="flex flex-col w-full md:w-1/2 gap-5">
                          <CustomInput
                            type="text"
                            placeHolder="Expiry Month"
                            name="expiry_month"
                            required={true}
                          />
                          <CustomInput
                            type="text"
                            placeHolder="Expiry Year"
                            name="expiry_year"
                            required={true}
                          />
                        </div>
                        <div className="flex flex-col w-full md:w-1/2 gap-5">
                          <CustomInput
                            type="text"
                            placeHolder="CVV"
                            name="cvv"
                            required={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="pt-5">
            <button
              type="submit"
              className="w-full bg-[#3DB368] text-white py-3 rounded-md"
              disabled={isPending}
            >
              {isPending ? "Processing..." : "Book Now"}
            </button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default Book;

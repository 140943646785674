import ReactPaginate from "react-paginate";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import PropTypes from "prop-types";

const Pagination = ({ handlePageChange, total, currentPage, limit }) => {
  if (total === 0) return null;

  const pageCount = Math.ceil(total / limit);

  return (
    <div className="mt-5">
      <ReactPaginate
        previousLabel={
          <a
            href="#"
            className={`bg-blue-500 p-3 text-white rounded-md cursor-pointer flex items-center justify-center ${
              currentPage === 0 ? "opacity-50 cursor-not-allowed" : ""
            }`}
            onClick={() => {
              if (currentPage > 0) {
                handlePageChange({ selected: currentPage - 1 });
              }
            }}
          >
            <FaArrowLeft />
          </a>
        }
        nextLabel={
          <a
            href="#"
            className={`bg-blue-500 p-3 text-white rounded-md cursor-pointer flex items-center justify-center ${
              currentPage === pageCount - 1
                ? "opacity-50 cursor-not-allowed"
                : ""
            }`}
            onClick={() => {
              if (currentPage < pageCount - 1) {
                handlePageChange({ selected: currentPage + 1 });
              }
            }}
          >
            <FaArrowRight />
          </a>
        }
        breakLabel={<span className="px-4 py-2">...</span>}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={3}
        onPageChange={handlePageChange}
        containerClassName="flex gap-2 "
        pageLinkClassName="flex justify-center items-center px-4 py-2 text-white rounded-md cursor-pointer bg-blue-500"
        activeLinkClassName="bg-red-500 text-white rounded-md"
        disabledClassName="opacity-50 cursor-not-allowed"
        initialPage={currentPage}
        forcePage={currentPage}
      />
    </div>
  );
};

Pagination.propTypes = {
  handlePageChange: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
};

export default Pagination;

import { useEffect } from "react";

const useClickOutside = (ref, visible, setVisible) => {
  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!ref.current) return;

      if (!visible || (ref.current && ref.current.contains(target))) {
        return;
      }
      setVisible(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setVisible, visible]);
};

export default useClickOutside;

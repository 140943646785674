// headUtils.js (or you can include this directly in main.jsx)

export const updateHead = () => {
    // Create a favicon link element
    const favicon = document.createElement('link');
    favicon.rel = 'icon';
    favicon.href = '/favicon.ico';
    document.head.appendChild(favicon);
  
    // Create a meta description tag
    const metaDescription = document.createElement('meta');
    metaDescription.name = 'description';
    metaDescription.content = 'Your site description here';
    document.head.appendChild(metaDescription);
  
    // Add any other meta tags you need here
    // Example: Meta for viewport
    const metaViewport = document.createElement('meta');
    metaViewport.name = 'viewport';
    metaViewport.content = 'width=device-width, initial-scale=1.0';
    document.head.appendChild(metaViewport);
  
    // Example: Meta for robots
    const metaRobots = document.createElement('meta');
    metaRobots.name = 'robots';
    metaRobots.content = 'index, follow';
    document.head.appendChild(metaRobots);
  };
  
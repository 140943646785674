const ContactMap = () => {
  return (
    <div>
      <div className="mt-10">
        <iframe
          className="w-full px-10 h-80 md:h-96"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3532.0121017669335!2d85.30665467418324!3d27.716912625071874!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb18e34c914e57%3A0x7985337af0f03df7!2sGlory%20of%20Nepal%20Travels%20%26%20Tours%20Pvt.%20Ltd.!5e0!3m2!1sen!2snp!4v1719738512926!5m2!1sen!2snp"
          width="600"
          height="450"
          // allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

export default ContactMap;

import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import PropTypes from "prop-types";

const CustomInput = ({
  defaultValue,
  name,
  placeHolder,
  type,
  required,
  styles,
}) => {
  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext();

  useEffect(() => {
    if (defaultValue) {
      setValue(name, defaultValue);
    }
  }, [defaultValue, name, setValue]);

  const validationRules = {};

  if (required) {
    validationRules.required = "This field is required";
  }

  if (type === "email") {
    validationRules.pattern = {
      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      message: "Invalid email address",
    };
  }

  return (
    <div className="flex flex-col gap-2 w-full rounded-md ">
      <input
        type={type}
        defaultValue={defaultValue}
        className={`w-full outline-none py-2 px-4 rounded-md text-sm text-slate-500 border border-[#CACACA] ${styles}`}
        placeholder={placeHolder}
        {...register(name, validationRules)}
      />
      {required && errors[name] && (
        <span className="text-red-500 text-sm">
          {errors[name]?.message?.toString()}
        </span>
      )}
    </div>
  );
};

CustomInput.propTypes = {
  defaultValue: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeHolder: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  required: PropTypes.bool,
  styles: PropTypes.string,
};

export default CustomInput;

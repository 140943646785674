import tourData from "@/api/fetchTourData";
import EndImage from "@/assets/images/endImage.avif";
import CallbackForm from "@/components/common/CallbackForm";
import { CheckIcon, Cross1Icon, StarFilledIcon } from "@radix-ui/react-icons";
import { useEffect, useState } from "react";
import { GiKnifeFork } from "react-icons/gi";
import { TiTick } from "react-icons/ti";
import { useNavigate, useParams } from "react-router-dom";

import Reviews from "@/components/common/Reviews";
import TourDetailsImageCarousel from "@/components/common/TourDetailsImageCarousel";
import ReviewRating from "@/components/review/Reviewrating";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";
import { currency } from "@/constants/currency";
import { useGetSinglePackageDetails } from "@/hooks/package.hook";
import {
  calculateDiscountedPrice,
  calculateTotalPrice,
} from "@/utils/calclulatePrice";
import { getValue } from "@/utils/object";
import { PATH } from "@/constants/path";

function TourDetails() {
  const { id } = useParams();
  const navigate = useNavigate();

  const tourName = "Glimpse of Switzerland | FREE FIFA Museum Tickets";

  const { data: singlePackageDetail } = useGetSinglePackageDetails(
    id ? id : ""
  );

  const [data, setData] = useState([]);

  useEffect(() => {
    setData({
      ...tourData,
    });
  }, [tourName]);

  return (
    data &&
    data.images &&
    data.images[1] && (
      <div>
        <div className="relative -mb-0 group">
          {getValue(singlePackageDetail, "images") && (
            <TourDetailsImageCarousel
              className="h-[30vh] md:h-[60vh]"
              infiniteScroll
              images={getValue(singlePackageDetail, "images")}
            />
          )}
          <div className="bottom-0 left-0 absolute bg-gradient-to-t from-stone-700 to-transparent shadow-xl backdrop-blur-[1px] w-full text-white">
            <div className="flex flex-col justify-center items-center gap-3 banner">
              <div className="font-semibold text-sm md:text-xl">
                {getValue(singlePackageDetail, "day_plans").length} Days &{" "}
                {getValue(singlePackageDetail, "day_plans").length - 1} Nights
                Tour
              </div>
              <h1 className="w-full font-bold text-2xl text-center md:text-6xl">
                <span className="border-b border-b-yellow-600 whitespace-nowrap">
                  {getValue(singlePackageDetail, "title")}
                </span>
              </h1>
              <p className="flex flex-col items-center text-xs md:text-xl">
                Starting at just{" "}
                <span className="font-bold text-xl text-yellow-600 md:text-2xl">
                  {currency.Dollar}{" "}
                  {calculateTotalPrice(
                    getValue(singlePackageDetail, "price"),
                    getValue(singlePackageDetail, "discount_percent")
                  ).toFixed(0)}
                </span>
                <span className="text-sm text-stone-400 md:text-md line-through">
                  {currency.Dollar}{" "}
                  {calculateDiscountedPrice(
                    getValue(singlePackageDetail, "price"),
                    getValue(singlePackageDetail, "discount_percent")
                  ).toFixed(0)}
                </span>
              </p>
              <div className="pb-5">
                <button
                  onClick={() =>
                    navigate(`${PATH.book}/${singlePackageDetail.id}`)
                  }
                  className="border-0 bg-yellow-600 hover:bg-yellow-500 m-0 px-4 py-2 rounded-full w-full font-bold text-center text-white hover:text-white outline-0"
                >
                  Book Now
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-center items-center gap-4 md:gap-10 bg-stone-700 shadow-lg m-0 -mt-[1px] px-2 py-0 w-full text-white">
          {getValue(singlePackageDetail, "day_plans") &&
            getValue(singlePackageDetail, "day_plans").map((item, index) => (
              <>
                <p
                  key={index}
                  className="flex flex-row items-center gap-1 py-3 text-xs md:text-2xl"
                >
                  <HoverCard>
                    <HoverCardTrigger asChild>
                      <p className="flex flex-row items-center gap-1 border-b border-b-transparent hover:border-b-yellow-600 text-xl capitalize">
                        {item.location}
                      </p>
                    </HoverCardTrigger>
                    <HoverCardContent
                      className="bg-slate-800/80"
                      side="top"
                      asChild
                    >
                      <p className="border-0 m-0 p-1 rounded-sm text-center text-white text-xs">
                        {item.description}
                      </p>
                    </HoverCardContent>
                  </HoverCard>
                </p>
                {index !== data.places.length - 1 && (
                  <div className="p-0 h-full text-xl text-yellow-600">&gt;</div>
                )}
              </>
            ))}
        </div>
        <div className="m-auto py-10 w-11/12 md:w-10/12 body">
          {/* <div className="pb-10 font-bold text-3xl text-center text-yellow-600">
            {getValue(singlePackageDetail, "title")}
          </div> */}
          <div className="flex flex-col">
            <h1 className="pb-5 font-bold text-3xl text-center">
              Tour Overview
            </h1>
            <p className="text-justify text-sm">
              {getValue(singlePackageDetail, "trip_highlight_text")}
            </p>
          </div>

          <div className="gap-3 grid md:grid-cols-8 pt-10">
            <div className="md:col-span-5">
              <h1 className="pb-5 font-bold text-3xl text-center md:text-left">
                Tour Highlights
              </h1>
              <ul className="text-sm">
                {singlePackageDetail &&
                  singlePackageDetail?.highlights?.map((item, index) => (
                    <li key={index} className="flex flex-row gap-2 py-1">
                      <span className="text-lg text-yellow-600">
                        <CheckIcon />
                      </span>
                      <span>{item.data}</span>
                    </li>
                  ))}
              </ul>

              <div className="flex flex-col pt-10">
                <h1 className="pb-5 font-bold text-3xl text-center md:text-left">
                  Itinerary
                </h1>
                <div className="flex flex-col">
                  {getValue(singlePackageDetail, "day_plans") &&
                    getValue(singlePackageDetail, "day_plans").map((day) => (
                      <div className="my-2 p-1 border rounded" key={day.id}>
                        <Accordion type="single" collapsible className="w-full">
                          <AccordionItem className="border-none" value="item-1">
                            <AccordionTrigger className="hover:no-underline">
                              <div className="flex flex-row items-center gap-3">
                                <div className="flex justify-center items-center bg-gradient-to-bl from-pink-600 to-yellow-600 px-2 p-1 rounded-md w-20 h-8 text-white text-xs">
                                  Day {day.day_number}
                                </div>
                                <p>{day.title}</p>
                              </div>
                            </AccordionTrigger>
                            <AccordionContent>
                              <div className="m-auto w-11/12 text-xs">
                                <p className="pb-2 border-b text-start">
                                  {day.description}
                                </p>
                              </div>

                              <div className="m-auto md:w-11/12 text-xs">
                                {day.meals_offered && (
                                  <div className="flex gap-5 pt-5">
                                    <GiKnifeFork className="mt-1 text-red-500" />
                                    <div className="flex flex-col gap-2">
                                      <div className="font-semibold">MEALS</div>
                                      {day?.meals_offered?.map((item) => (
                                        <div
                                          key={item.id}
                                          className="flex items-start gap-2 font-light"
                                        >
                                          <TiTick color="red" /> {item?.name}
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </AccordionContent>
                          </AccordionItem>
                        </Accordion>
                      </div>
                    ))}
                </div>
              </div>
            </div>

            {/* right side div this is hidden in small screen */}
            <div className="md:block top-20 sticky hidden md:col-span-3 bg-gray-100/50 shadow-lg p-5 border rounded-md min-w-[400px] max-w-[500px] h-max">
              <h1 className="font-bold text-center">Enquiry Form</h1>
              <em className="block font-thin text-center text-xs">
                **Fill up this form so that we can contact you
              </em>
              <CallbackForm
                buttonName="Send Enquiry"
                id={getValue(singlePackageDetail, "id")}
              />
            </div>
          </div>
          <div className="mt-10 w-full">
            <img src={EndImage} alt="" />
          </div>
          <div className="block md:hidden md:col-span-3 bg-gray-100/50 shadow-lg p-5 border rounded-md h-max">
            <h1 className="font-bold text-center">Enquiry Form</h1>
            <em className="block font-thin text-center text-xs">
              **Fill up this form so that we can contact you
            </em>
            <CallbackForm
              buttonName="Send Enquiry"
              id={getValue(singlePackageDetail, "id")}
            />
          </div>
          <div className="shadow-md mt-8 p-4 border roounded-lg">
            <h1 className="pb-2 border-b font-bold text-xl md:text-2xl">
              Whats inside the Package?
            </h1>
            <div className="gap-5 grid grid-cols-1 md:grid-cols-2 pt-3 w-full text-center">
              <div className="mr-5 border-r inclusions">
                <h1 className="pb-1 pl-6 md:pl-0 font-bold text-left text-sm md:text-sm md:text-left">
                  Inclusions
                </h1>

                {singlePackageDetail &&
                  singlePackageDetail.included_itinerary?.map((item, index) => (
                    <ul className="text-sm" key={index}>
                      <li className="flex flex-row items-top gap-2 text-left">
                        <span className="text-lg text-yellow-600">
                          <CheckIcon />{" "}
                        </span>
                        <span className="text-xs">{item.data}</span>
                      </li>
                    </ul>
                  ))}
              </div>
              <div className="exclusions">
                <h1 className="pb-1 pl-6 md:pl-0 font-bold text-left text-sm md:text-sm">
                  Exclusions
                </h1>

                {singlePackageDetail &&
                  singlePackageDetail?.excluded_itinerary?.map(
                    (item, index) => (
                      <ul className="text-sm" key={index}>
                        <li
                          key={index}
                          className="flex flex-row items-top gap-2 text-left"
                        >
                          <span className="text-lg text-yellow-600">
                            <Cross1Icon />{" "}
                          </span>
                          <span className="text-xs">{item.data}</span>
                        </li>
                      </ul>
                    )
                  )}
              </div>
            </div>
          </div>
          <div className="pt-10">
            {data?.goodToKnows && (
              <Accordion
                className="shadow-sm px-4 border rounded-sm"
                collapsible
                defaultValue="good to know"
              >
                <AccordionItem className="border-0" value="good to know">
                  <AccordionTrigger className="hover:no-underline" aschild>
                    <p className="font-bold">Good to knows</p>
                  </AccordionTrigger>

                  <AccordionContent>
                    <ul className="ml-9 list-disc list-outside">
                      <li className="px-1 py-1">
                        {getValue(singlePackageDetail, "cancellation")}
                      </li>
                      <li className="px-1 py-1">
                        {getValue(singlePackageDetail, "payment")}
                      </li>
                      <li className="px-1 py-1">
                        {getValue(singlePackageDetail, "travel_insurance")}
                      </li>
                      <li className="px-1 py-1">
                        {getValue(singlePackageDetail, "visa")}
                      </li>
                    </ul>
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            )}
          </div>

          <div className="pt-20">
            <h1 className="pb-5 font-bold text-3xl text-center">
              Reviews and Ratings
            </h1>
            <Reviews reviewsData={data.ratings} />
          </div>
          <div className="flex flex-col gap-3">
            {data.reviews &&
              data.reviews.map((reviewer, index) => {
                return (
                  <div className="flex flex-col p-2 border rounded" key={index}>
                    <div className="flex flex-row justify-between items-top">
                      <div className="flex flex-row gap-2">
                        <img
                          className="rounded-full w-10 h-10"
                          src={reviewer.image}
                          alt=""
                        />
                        <div>
                          <p className="font-bold font-sm">{reviewer.name}</p>
                        </div>
                      </div>
                      <p className="flex flex-row items-center md:mr-5 text-yellow-600 self-start">
                        <StarFilledIcon />
                        {Number(reviewer?.rating).toFixed(1)}/5
                      </p>
                    </div>
                    <p className="p-2 text-sm">{reviewer.review}</p>
                  </div>
                );
              })}
          </div>
          <ReviewRating id={getValue(singlePackageDetail, "id")} />
        </div>
      </div>
    )
  );
}

export default TourDetails;

const PRODUCTION_ENV = "production";
const STAGING_ENV = "staging";

export const BASE_URL = (() => {
  switch (import.meta.env.VITE_ENVIRONMENT) {
    case PRODUCTION_ENV:
      return "https://api.travelnepal.com.au";
    case STAGING_ENV:
      return "https://api.gloryworldholidays.com";
    default:
      return "https://api.gloryworldholidays.com";
  }
})();

export const AUTH_COOKIE_CONFIG = {
  BEARER_TOKEN: "bearer-token",
  loggedInCookie: "isLoggedIn",
  ACCESS_TOKEN: "glory-user-access-token",
  REFRESH_TOKEN: "glory-user-refresh-token",
};

/*
const PRODUCTION_ENV = "production";
const STAGING_ENV = "staging";

const API_URL = process.env.NEXT_PUBLIC_API_BASE_URL;


export const BASE_URL = (() => {
  switch (process.env.NEXT_PUBLIC_ENVIRONMENT) {
    case PRODUCTION_ENV:
      return "https://api.hye.com.np";
    case STAGING_ENV:
      return "https://api.hye.com.np";
    default:
      return "http://127.0.0.1:8000";
  }
})();

export const AUTH_COOKIE_CONFIG = {
  BEARER_TOKEN: "bearer-token",
  loggedInCookie: "isLoggedIn",
  ACCESS_TOKEN: "hye-user-access-token",
  REFRESH_TOKEN: "hye-user-refresh-token",
  
};

*/

import Mission from "../../assets/images/mission.webp";
import Vision from "../../assets/images/vision.webp";
const MissionVision = () => {
  return (
    <>
      <div className="py-10 md:py-20 px-5 md:px-20 lg:px-28 bg-white">
        <div className="flex flex-col gap-5 justify-center items-center">
          <h1 className="text-center font-bold text-2xl md:text-4xl font-sans max-w-md">
            Provide The Best Travel Experience
          </h1>
          <p className="max-w-2xl text-center text-[#4F5E70]">
            We understand that every journey has unique needs. Therefore, we
            offer customized travel packages designed according to your
            preferences and budget.
          </p>
        </div>
        <div className="w-full flex flex-col sm:flex-row gap-5 pt-10 md:pt-20">
          <div className="w-full flex flex-col gap-5 p-5 bg-[#E6F6F4]  rounded-lg">
            <img src={Vision} alt="" className="h-20 w-20 " />
            <h1 className="font-bold text-2xl">Our Vision</h1>
            <p className="text-[#4F5E70] rounded-lg">
              Our vision is to offer a luxury service to all those clients who
              are looking for that special care and also provide affordable
              services to all who wants to explore the mountains. We are
              determined to change the way people travel and build trustworthy
              relations to built on our Clientele. We are looking for an
              explorer who will be our brand advocates.
            </p>
          </div>
          <div className="w-full flex flex-col gap-5 p-5 bg-[#E6F6F4]  rounded-lg">
            <img src={Mission} alt="" className="h-20 w-20" />
            <h1 className="font-bold text-2xl">Our Mission</h1>
            <p className="text-[#4F5E70]  rounded-lg">
              Our mission is to provide Absolutely Amazing Adventure as our
              Motto. We strive in providing you a complete stress free travel
              plan. Once you book our tour/packages you can sit back and relax.
              We will be with you in your adventure from day one to your
              departure day. Quality service and excellence hospitality is what
              we are proud of.
            </p>
          </div>
          <div className="w-full flex flex-col gap-5 p-5   bg-[#E6F6F4]  rounded-lg">
            <img src={Mission} alt="" className="h-20 w-20" />
            <h1 className="font-bold text-2xl">Our Values</h1>
            <p className="text-[#4F5E70] rounded-lg">
              At the heart of every great travel website lies a deep commitment
              to fostering a spirit of wanderlust, exploration, and personal
              growth. These platforms strive to make travel more accessible and
              convenient, providing user-friendly booking tools and personalized
              trip planning services.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default MissionVision;

import PropTypes from "prop-types";
import DestinationCard from "./DestinationCard";
import ComponentCarousel from "./ComponentCarousel";

function PlacesCarousel({ data }) {
  return (
    <div className="w-full flex flex-col h-auto">
      <div className="w-full flex flex-row gap-3 h-full">
        <ComponentCarousel>
          {data &&
            data.map((place, index) => (
              <DestinationCard place={place} key={index} />
            ))}
        </ComponentCarousel>
      </div>
    </div>
  );
}

PlacesCarousel.propTypes = {
  data: PropTypes.array.isRequired,
  destination: PropTypes.string.isRequired,
};

export default PlacesCarousel;

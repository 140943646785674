import PropTypes from "prop-types";
import { TiTick } from "react-icons/ti";
import { IoMdStar, IoMdStarHalf } from "react-icons/io";
import { getValue } from "@/utils/object";
import { useNavigate } from "react-router-dom";

const CartDetailsUser = ({ packageDetails }) => {
  const navigate = useNavigate();

  const handleViewTour = (e, id) => {
    e.preventDefault();
    navigate(`/tour/view/${id}`);
  };

  return (
    <div className="flex flex-col gap-5 justify-between mt-8 border-b-none md:border-b-[1px] border-[1px] lg:border-none rounded-r-md ">
      {packageDetails &&
        packageDetails.map((item) => (
          <div
            key={item.id}
            className="flex flex-col md:flex-row bg-white justify-between p-5 rounded-md "
          >
            <div className="flex flex-col md:flex-row font-bold text-xl ">
              <div className="w-full md:w-auto border-r-none lg:border-r-[1px] border-b-[1px] bg-white ">
                <img
                  src={getValue(item, "[0].package.images[0].image")}
                  className="h-auto md:h-[200px] w-full rounded-t-none md:rounded-t-none lg:rounded-t-md object-cover"
                  alt="cartDetailsUserImage"
                  height={1000}
                  width={1000}
                />
                <div className="w-full flex p-2 border-l ">
                  <div className="flex flex-col justify-center items-start text-[10px] lg:text-xs text-[#767676] w-1/2 ">
                    <div>Starts </div>
                    <span className="text-black ">
                      {getValue(item, "[0].package.start_location")}
                    </span>
                  </div>
                  <div className="flex flex-col justify-center items-center text-[10px] lg:text-xs text-[#767676] ">
                    <div>Ends </div>
                    <span className="text-black ">
                      {getValue(item, "[0].package.end_location")}
                    </span>
                  </div>
                </div>
              </div>
              <div className="pt-5 px-5 md:px-5 lg:py-0 ">
                <div className="font-bold text-xs md:text-sm lg:text-2xl">
                  {getValue(item, "[0].package.title")}
                </div>
                <div>
                  <div className="flex flex-row justify-start items-center gap-2 my-2">
                    <div className="flex text-[#FFAB52] text-xs md:text-lg">
                      <IoMdStar /> <IoMdStar /> <IoMdStar /> <IoMdStar />{" "}
                      <IoMdStarHalf />{" "}
                    </div>
                    <div className="font-normal text-xs md:text-md text-[#767676]">
                      of 200 reviews
                    </div>
                  </div>
                  <div className="flex flex-row gap-5 md:gap-5 text-sm font-light md:font-normal ">
                    <div className="flex items-center gap-1 ">
                      <TiTick color="red" /> Best price guaranteed
                    </div>
                    <div className="flex items-center gap-1 ">
                      <TiTick color="red" /> No booking fees
                    </div>
                  </div>
                  <div className="hidden md:flex text-xs font-thin mt-2 leading-2 md:leading-none">
                    Excellent Tour Director and Excellent Driver. Very
                    knowledgeable tour director and very experienced
                  </div>
                </div>
                <div className="flex flex-col gap-1 text-xs pt-5">
                  <div className="text-[#8d8989]">
                    Tour Type :{" "}
                    <span className="text-black">
                      {getValue(item, "[0].package.tour_type.name")}
                    </span>
                  </div>

                  <div className="text-[#8d8989]">
                    Accommodation :{" "}
                    <span className="text-black">
                      {getValue(item, "[0].package.accommodation.name")}
                    </span>
                  </div>
                  <div className="text-[#8d8989]">
                    Transport :{" "}
                    <span className="text-black">
                      {getValue(item, "[0].package.transport.name")}
                    </span>
                  </div>
                  <div className="text-[#8d8989]">
                    Age range :{" "}
                    <span className="text-black">
                      {getValue(item, "[0].package.age_range.name")}
                    </span>
                  </div>
                  <div className="text-[#8d8989]">
                    Operated In :{" "}
                    <span className="text-black">
                      {getValue(item, "[0].package.operated_in")}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-5 p-5 ">
              <div className="flex gap-5 justify-between pt-5 md:pt-0">
                <div>
                  <div className="font-bold text-xs">
                    <div className="text-xs">Days 05</div>
                  </div>
                  <div className="flex items-center gap-1 text-[#DD2D2D] text-xs">
                    <span className="text-black font-semibold">From</span>
                    <p className="relative overflow-hidden text-sm">
                      <span className="relative z-10 text-xs">
                        $ {getValue(item, "price")}
                      </span>
                      <span className="absolute inset-0 flex items-center justify-center">
                        <span className="h-0.5 w-full bg-red-500"></span>
                      </span>
                    </p>{" "}
                  </div>
                  <div className="text-end text-xs font-semibold">
                    ${" "}
                    {getValue(item, "[0].package.price") -
                      getValue(item, "[0].package.discount_percent")}
                  </div>
                  <div className="text-xs font-semibold">
                    Saving $ {getValue(item, "[0].package.discount_percent")}
                  </div>
                </div>
              </div>

              <button
                className="w-full lg:w-[120px] py-4 px-5 bg-[#3DB367] text-white text-md md:text-xs rounded-md"
                onClick={(e) => handleViewTour(e, getValue(item, "id"))}
              >
                View Tour
              </button>
            </div>
          </div>
        ))}
    </div>
  );
};

CartDetailsUser.propTypes = {
  packageDetails: PropTypes.arrayOf(PropTypes.object).isRequired,
  item: PropTypes.object,
};

export default CartDetailsUser;

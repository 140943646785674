import PropTypes from "prop-types";
import {
  Carousel,
  CarouselItem,
  CarouselContent,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import Autoplay from "embla-carousel-autoplay";
import { getValue } from "@/utils/object";
import { checkIfEmpty } from "@/utils/validation";
import { useNavigate } from "react-router-dom";

function ImageCarousel({
  images,
  className = "",
  infiniteScroll = false,
  data,
  id,
}) {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/tours/${id}`);
  };

  return (
    <Carousel
      plugins={[
        Autoplay({
          delay: 1500 + Math.random() * 1500,
          stopOnLastSnap: !infiniteScroll,
        }),
      ]}
      className="group"
      opts={{ watchDrag: false }}
    >
      <CarouselPrevious className="left-0 md:hidden md:group-hover:inline-flex z-50 opacity-80 disabled:opacity-0" />
      <CarouselContent className={`${className}`}>
        {images.map((item) => (
          <CarouselItem key={item.id}>
            <button className="w-full relative" onClick={handleClick}>
              <img
                className="rounded w-full h-[300px] object-cover"
                src={getValue(item, "image")}
                alt={`image-${item.id}`}
              />
              {!checkIfEmpty(getValue(data, "[0].name")) && (
                <div className="absolute top-2 left-2 p-1 bg-secondary rounded-md text-white text-xs">
                  {getValue(data, "[0].name")}
                </div>
              )}
            </button>
          </CarouselItem>
        ))}
      </CarouselContent>
      <CarouselNext className="right-0 md:hidden md:group-hover:inline-flex z-50 opacity-80	 disabled:opacity-0" />
    </Carousel>
  );
}

ImageCarousel.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  className: PropTypes.string,
  infiniteScroll: PropTypes.bool,
  data: PropTypes.array,
  id: PropTypes.string,
};

export default ImageCarousel;

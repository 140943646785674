import InternationalDestination from "@/components/destination/international/InternationalDestination";
import DomesticDestination from "@/components/destination/national/NationalTour";
import FeatureDListCarasouel from "@/components/home/FeaturedCarausel";
import { useGetAllPackageCMS } from "@/hooks/package.hook";
import { getValue } from "@/utils/object";
import { useNavigate } from "react-router-dom";
import HomeLanding from "@/components/landing/landing";

export default function Home() {
  const { data: allPackages } = useGetAllPackageCMS({ limit: 9, offset: 0 });
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/tour");
  };

  return (
    <>
      <HomeLanding />

      <div className="flex flex-col gap-10 py-10">
        <div>
          <div className="flex justify-between px-5 md:px-16 mb-3">
            <div className="text-xl md:text-3xl">
              <span className="font-bold">Featured </span>
              <span className="font-bold">Tours</span>
            </div>
            <button
              onClick={handleNavigate}
              className="bg-primary hover:bg-secondary py-1 px-5 text-white text-sm rounded-md"
            >
              View All
            </button>
          </div>
          <FeatureDListCarasouel
            data={getValue(allPackages, "results", [])} // Default to empty array if packages are not available
            key={getValue(allPackages, "id")}
          />
        </div>
        <DomesticDestination />
        <InternationalDestination />
      </div>
    </>
  );
}

import hands from "../../assets/images/test-image.jpg";

const Join = () => {
  return (
    <>
      <div className="relative bg-gray-100 h-[50vh] flex items-center justify-center">
        <div
          className="absolute top-0 left-0 w-full h-full bg-cover bg-center"
          style={{ backgroundImage: `url(${hands})` }}
        ></div>
        <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50 "></div>
        <div className="relative z-10 p-4 sm:p-8 max-w-md md:max-w-lg lg:max-w-xl text-center text-white">
          <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold pb-4 sm:pb-6">
            Join the Fun! We are Growing
          </h1>
          <button className="px-3 sm:px-4 py-1 text-sm sm:text-lg font-semibold text-white bg-[rgb(12,54,43)] rounded-full hover:bg-orange-600">
            <a href="/">View all destinations</a>
          </button>
        </div>
      </div>
    </>
  );
};

export default Join;

import PropTypes from "prop-types";
import DestinationCard from "./DestinationCard";
// import ComponentCarousel from "./ComponentCarousel";

function FeatureDListCarasouel({ data }) {
  return (
    <div className="w-full flex flex-col h-auto">
      <div className="w-full grid grid-cols-1  md:grid-cols-3 gap-3 h-full px-5 md:px-16">
        {/* <ComponentCarousel> */}
        {data &&
          data
            .slice(0, 9)
            .map((place, index) => (
              <DestinationCard place={place} key={index} />
            ))}
        {/* </ComponentCarousel> */}
      </div>
    </div>
  );
}

FeatureDListCarasouel.propTypes = {
  data: PropTypes.array.isRequired,
  destination: PropTypes.string.isRequired,
};

export default FeatureDListCarasouel;

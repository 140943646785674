import PropTypes from "prop-types";
import { Navigate, Outlet } from "react-router-dom";
import { PATH } from "../constants/path";

const PrivateRouteWrapper = ({
  isLoggedIn = false,
}) => {
  return isLoggedIn ? <Outlet /> : <Navigate to={PATH.login} />;
};

PrivateRouteWrapper.propTypes = {
  isLoggedIn: PropTypes.bool,
};

export default PrivateRouteWrapper;

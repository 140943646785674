
const HomeLanding = () => {
  return (
    <div className="w-full h-[400px] md:h-[450px] relative">
      <video
        className="absolute inset-0 w-full h-full object-cover"
        autoPlay
        loop
        muted
        playsInline
      >
        <source src="/landing.mp4" type="video/mp4" />
      </video>

      <div
        className="absolute inset-0 w-full h-full flex items-center justify-center"
        style={{
          background:
            "linear-gradient(199deg, rgba(0, 0, 0, 0.00) 54.18%, #000 98.52%)",
        }}
      ></div>

    </div>
  );
};

export default HomeLanding;

export const customOption = [
    {
      value: "MR",
      label: "MR",
    },
    {
      value: "MS",
      label: "MS",
    },
    {
      value: "MRS",
      label: "MRS",
    },
  ];
  
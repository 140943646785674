// eslint-disable-next-line react/prop-types
const TextWithBorderCenter = ({ text, style = "" }) => {
  return (
    <div className="flex items-center justify-center gap-2">
      <div className="border-b flex-grow border-gray-300"></div>
      <div className={`text-center text-sm ${style}`}>{text}</div>
      <div className="border-b flex-grow border-gray-300"></div>
    </div>
  );
};

export default TextWithBorderCenter;

import axios from "axios";
import { parseApiError } from "@/helper/error";
import { getCookie } from "@/utils/cookie";
import { AUTH_COOKIE_CONFIG, BASE_URL } from "@/constants/common";

const getToken = () => getCookie(AUTH_COOKIE_CONFIG.ACCESS_TOKEN);

axios.interceptors.request.use(
  async (config) => {
    return config;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

const get = async ({ url, params }) => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  const requestParams = {
    ...params,
  };

  const fullUrl = `${BASE_URL}/${url}`;

  return axios
    .get(fullUrl, {
      headers,
      params: requestParams,
      withCredentials: true,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      const errorMessage = parseApiError(error);
      throw Error(errorMessage);
    });
};

const post = async ({ url, body, contentType = "application/json" }) => {
  const fullUrl = `${BASE_URL}/${url}`;

  const headers = {
    Accept: "application/json",
    "Content-Type": contentType,
  };

  return axios
    .post(fullUrl, body, { headers, withCredentials: true })
    .then((response) => response.data)
    .catch((error) => {
      throw Error(parseApiError(error));
    });
};

const deleteApi = async ({ url }) => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  const fullUrl = `${BASE_URL}/${url}`;

  return axios
    .delete(fullUrl, { headers, withCredentials: true })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw Error(parseApiError(error));
    });
};

const put = async ({ url, body, contentType = "application/json" }) => {
  const fullUrl = `${BASE_URL}/${url}`;
  const headers = {
    Accept: "application/json",
    "Content-Type": contentType,
  };
  return axios
    .put(fullUrl, body, { headers, withCredentials: true })
    .then((response) => response.data)
    .catch((error) => {
      throw Error(parseApiError(error));
    });
};

const patch = async ({ url, body, contentType = "application/json" }) => {
  const fullUrl = `${BASE_URL}/${url}`;
  const headers = {
    Accept: "application/json",
    "Content-Type": contentType,
  };
  return axios
    .patch(fullUrl, body, { headers, withCredentials: true })
    .then((response) => response.data)
    .catch((error) => {
      throw Error(parseApiError(error));
    });
};

const getUser = async ({ url, params, header }) => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${header?.token}`,
  };

  const requestParams = {
    ...params,
  };

  const fullUrl = `${BASE_URL}/${url}`;

  return axios
    .get(fullUrl, {
      headers,
      params: requestParams,
      withCredentials: true,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      const errorMessage = parseApiError(error);
      throw Error(errorMessage);
    });
};

const postUser = async ({ url, body, contentType = "application/json" }) => {
  const fullUrl = `${BASE_URL}/${url}`;

  const headers = {
    Accept: "application/json",
    "Content-Type": contentType,
    Authorization: `Bearer ${getToken()}`,
  };
  return axios
    .post(fullUrl, body, { headers, withCredentials: true })
    .then((response) => response.data)
    .catch((error) => {
      throw Error(parseApiError(error));
    });
};

export { get, post, put, deleteApi, patch, postUser, getUser };

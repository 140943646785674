import { customList } from 'country-codes-list';

export const getCountriesCodeList = () => {
  const countriesCode = customList('countryCode', '{countryCode}');
  const countriesDailCode = customList('countryCode', '+{countryCallingCode}');

  return { countriesCode, countriesDailCode };
};

export default getCountriesCodeList;

import { useLocation } from "react-router-dom";
import { useVerifyAccount } from "@/hooks/auth.hook";
import  { useEffect } from "react";
import { showErrorMessage } from "@/utils/toast";
import { getValue } from "@/utils/object";

const VerifyEmail = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token") || "";

  const { mutateAsync: verifyAccount, isPending } = useVerifyAccount(token);

  useEffect(() => {
    if (token) {
      verifyAccount().catch((error) => {
        showErrorMessage(getValue(error, "message"));
      });
    }
  }, [token, verifyAccount]);

  return (
    <div className="w-[95%] md:w-[80%] mx-auto my-32">
      <div className="max-w-xl">
        {isPending ? (
          <>
            <div className="text-4xl font-bold">Verifying...... !!!!!!</div>
            <div className="py-5 text-gray-500">
              Thank you very much for signing up. please enter you token here
            </div>
          </>
        ) : (
          <>
            <div className="text-4xl font-bold">Verification Successful...  !!!!!!</div>
            <div className="py-5 text-gray-500">
              Thank you very much for signing up.
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default VerifyEmail;

import DestinationCard from "@/components/home/DestinationCard";
import TourHeader from "@/components/tour/TourHeader";
import { useGetAllPackageByPagination } from "@/hooks/package.hook";
import Pagination from "@/lib/Pagination";
import { getValue } from "@/utils/object";
import { useState } from "react";
const Tour = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const limit = 12;

  const { data: allPackages } = useGetAllPackageByPagination({
    offset: currentPage * limit,
    limit,
  });

  const packageData = getValue(allPackages, "results");

  const handlePageChange = (data) => {
    const selectedPage = data.selected;
    setCurrentPage(selectedPage);
  };
  return (
    <div>
      <TourHeader />
      <div className="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
        <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-x-4 gap-y-4 md:gap-5 py-10">
          {packageData &&
            packageData.map((place, index) => (
              <DestinationCard place={place} key={index} />
            ))}{" "}
        </div>
      </div>
      <div className="flex justify-center items-center pb-10">
        <Pagination
          handlePageChange={handlePageChange}
          total={allPackages && getValue(allPackages, "count")}
          currentPage={currentPage}
          limit={limit}
        />
      </div>
    </div>
  );
};

export default Tour;

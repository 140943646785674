import { useQuery } from "@tanstack/react-query";
import { get } from "../api/client";

const QUERY_TYPE = {
  domestic_type: "domestic_type",
  international_type: "international_type",
};

export const useGetAllDomesticPackageCount = () =>
  useQuery({
    queryKey: [QUERY_TYPE.domestic_type],
    queryFn: () => get({ url: "api/v1/location/list/city" }),
    select: (response) => {
      return response.results;
    },
  });

export const useGetAllDomesticPackage = (id) =>
  useQuery({
    queryKey: [QUERY_TYPE.domestic_type, id],
    queryFn: () => get({ url: `api/v1/packages/domestic/${id}/destinations` }),
    select: (response) => {
      return response;
    },
  });

export const useGetAllInternationalPackageCount = () =>
  useQuery({
    queryKey: [QUERY_TYPE.international_type],
    queryFn: () => get({ url: "api/v1/location/list/continent/" }),
    select: (response) => {
      return response.results;
    },
  });

export const useGetAllInternationalPackage = (id) =>
  useQuery({
    queryKey: [QUERY_TYPE.international_type, id],
    queryFn: () => get({ url: `api/v1/packages/continent/${id}/destinations` }),
    select: (response) => {
      return response.results;
    },
    refetchOnWindowFocus: false,
    staleTime: 1000,
    retry: 3,
  });

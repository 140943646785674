import PropTypes from "prop-types"; // Import PropTypes for prop validation
import { createContext, useState, useEffect } from "react";
import { AUTH_COOKIE_CONFIG } from "../constants/common";
import { getCookie } from "../utils/cookie";

export const AuthContextCreator = createContext({
  isLoggedIn: false,
  setIsLoggedIn: () => {},
  companyProfile: null,
  setCompanyProfile: () => {},
});

const AuthContextProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [companyProfile, setCompanyProfile] = useState(null);

  useEffect(() => {
    const loginCookie = getCookie(AUTH_COOKIE_CONFIG.loggedInCookie);
    console.log("loginCookie:", loginCookie); // Debugging step
    if (loginCookie) {
      setIsLoggedIn(true);
    }
  }, []);

  const values = {
    isLoggedIn,
    setIsLoggedIn,
    companyProfile,
    setCompanyProfile,
  };

  return (
    <AuthContextCreator.Provider value={values}>
      {children}
    </AuthContextCreator.Provider>
  );
};

AuthContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContextProvider;

import TermsAndCondition from "@/components/terms/TermsAndCondition";
const Terms = () => {
  return (
    <div>
      <TermsAndCondition />
    </div>
  );
};

export default Terms;
